import { LoginResponse, SignupResponse } from './types';
import axios, { AxiosResponse } from 'axios';
import { config } from 'index';
import { LoginModel, SignupModel } from 'features/App/appSlice.state';

export const loginAsync = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const { data: loginResponse } = await axios.post<LoginModel, AxiosResponse<LoginResponse>>(
      config.apiLoginUrl,
      { email: email, password: password, apiVersion: config.apiVersion },
      { timeout: 5000 },
    );
    return Promise.resolve(loginResponse);
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return Promise.resolve({
        type: 'error',
        errorMessage: 'Unable to reach server.  Please check your connection or try again later.',
      });
    }
    if (err.response.data.type === 'error' && err.response.data.errorMessage) {
      return Promise.resolve(err.response.data);
    }
    return Promise.resolve({
      type: 'error',
      errorMessage: 'Unknown error occurred.  Please check your connection or try again later.',
    });
  }
};

export const signupAsync = async (
  email: string,
  password: string,
  fullName: string,
  promoCode: string,
): Promise<SignupResponse> => {
  try {
    const { data: signupResponse } = await axios.post<SignupModel, AxiosResponse<SignupResponse>>(
      config.apiSignupUrl,
      { email: email, password: password, name: fullName, promoCode: promoCode, apiVersion: config.apiVersion },
      { timeout: 10000 },
    );
    return Promise.resolve(signupResponse);
  } catch (err) {
    if (err.code === 'ECONNABORTED') {
      return Promise.resolve({
        type: 'error',
        errorMessage: 'Unable to reach server.  Please check your connection or try again later.',
      });
    }
    if (err.response.data.type === 'error' && err.response.data.errorMessage) {
      return Promise.resolve(err.response.data);
    }
    return Promise.resolve({
      type: 'error',
      errorMessage: 'Unknown error occurred.  Please check your connection or try again later.',
    });
  }
};
