import React, { ChangeEvent, useRef, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  useTheme,
  Chip,
  Avatar,
  Paper,
  useMediaQuery,
  Popper,
  Fade,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './HeaderStyles';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { useTypedSelector } from 'features/rootReducer';
import {
  getAllContexts,
  getSearchParameters,
  getSearchActive,
  getActiveContext,
} from 'features/App/appSlice.selectors';
import { useDispatch } from 'react-redux';
import { startSearchAsync, removeSearchTagAsync } from 'features/App/appSlice.thunks';
import { eventNames } from 'cluster';
import { setSearchActive } from 'features/App/appSlice';
import { useHotkeys } from 'react-hotkeys-hook';
import { services } from 'features/App/App';
import { HeaderSearch } from './HeaderSearch';

export interface IHeaderProps {
  drawerToggle: () => void;
  changeContext: (context: PouchContext) => void;
  currentContextName: string;
  search: (query: string, tags: string[]) => void;
}

// Attempt to measure / offset iOS keyboard for dialog.
// //@ts-ignore
// var viewport = window.visualViewport;
// viewport.onresize = () => {
//   var offsetLeft = 30; //viewport.width;
//   var offsetTop = 30; //viewport.height - 300;
//   // - 100 ;layoutViewport.getBoundingClientRect().height
//   // + viewport.offsetTop;
//   var element = document.getElementsByClassName('MuiDialog-paper')[0] as HTMLElement;
//   // You could also do this by setting style.left and style.top if you
//   // use width: 100% instead.
//   if (element == null) {
//     return;
//   }
//   element.style.transform =
//     'translate(' + offsetLeft + 'px,' + offsetTop + 'px) ' + 'scale(' + 1 / viewport.scale + ')';
// };

export interface SearchSuggestion {
  value: string;
  description?: string;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

export const Header = (props: IHeaderProps) => {
  const { drawerToggle, currentContextName, changeContext } = props;

  const [contextMenuAnchor, setContextMenuAnchor] = useState<null | HTMLElement>(null);

  /* New Context dialog */
  const [newContextDialog, setNewContextDialog] = useState<boolean>(false);
  const [newContextName, setNewContextName] = useState<string>('');

  const searchInputRef = useRef<HTMLInputElement>(null);

  useHotkeys(
    'ctrl+f',
    (e: KeyboardEvent) => {
      e.preventDefault();
      searchInputRef && searchInputRef.current && searchInputRef.current.focus();
    },
    { filter: () => true },
  );

  const dispatch = useDispatch();

  const handleNewContextNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewContextName(event.target.value);
  };
  /* */
  const theme = useTheme();
  const classes = useStyles();
  const allcontexts = useTypedSelector(getAllContexts);

  const searchActive = useTypedSelector(getSearchActive);
  const searchParameters = useTypedSelector(getSearchParameters);

  // const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setContextMenuAnchor(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setContextMenuAnchor(event.currentTarget);
  };
  const selectContext = (context: PouchContext) => {
    changeContext(context);
    setContextMenuAnchor(null);
  };
  const createNewContext = () => {
    // dispatch(services.addContext(newContextName));
    setContextMenuAnchor(null);
    setNewContextDialog(false);
  };
  const showCreateNewContext = () => {
    setContextMenuAnchor(null);
    setNewContextDialog(true);
  };

  const handleNewContextClose = () => {
    setNewContextDialog(false);
  };

  const search = () => {
    props.search('query', ['tags']);
  };
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={drawerToggle}
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>

          {/* <Slide direction="down" in={!searchFocused} timeout={{ enter: 250, exit: 500 }} unmountOnExit={true}> */}
          {!(isSmallScreen && searchActive) ? (
            <div className={classes.title}>
              <Typography variant="h5" noWrap>
                {/* {isSmallScreen && searchFocused ? (
                <span onClick={() => alert()} className={classes.contextDropdown}>
                  Filter <KeyboardArrowDown style={{ verticalAlign: 'text-bottom' }}></KeyboardArrowDown>
                </span>
              ) : ( */}
                <span onClick={handleClick} className={classes.contextDropdown}>
                  {currentContextName} <KeyboardArrowDown style={{ verticalAlign: 'text-bottom' }}></KeyboardArrowDown>
                </span>
                {/* )} */}
              </Typography>
            </div>
          ) : (
            <div></div>
          )}
          {/* </Slide>

            <Slide
              direction="down"
              in={searchFocused}
              timeout={{ appear: 500, enter: 500, exit: 0 }}
              unmountOnExit={true}
            >
              <div className={classes.title}>
                <Typography variant="h5" noWrap>
                  <span onClick={handleClick} className={classes.contextDropdown}>
                    Filter<KeyboardArrowDown style={{ verticalAlign: 'text-bottom' }}></KeyboardArrowDown>
                  </span>
                </Typography>
              </div>
            </Slide> 
          // </>
        // ) : (
        //   <div className={classes.title}>
        //     <Typography variant="h5" noWrap>
        //       <span onClick={handleClick} className={classes.contextDropdown}>
        //         {currentContextName} <KeyboardArrowDown style={{ verticalAlign: 'text-bottom' }}></KeyboardArrowDown>
        //       </span>
        //     </Typography>
        //   </div>
        // )}*/}
          <HeaderSearch searchInputRef={searchInputRef} />
        </Toolbar>
        {searchParameters.tag_ids.length > 0 ? (
          <Toolbar className={classes.panel}>
            <div className={classes.title}></div>
            <Paper className={classes.secondaryHeading}>
              <Typography className={classes.heading}>
                {searchParameters.tag_ids.map((tagId) => (
                  <Chip
                    variant="outlined"
                    color={tagId[0] === '@' ? 'primary' : 'secondary'}
                    onDelete={() => {
                      dispatch(removeSearchTagAsync(tagId));
                    }}
                    avatar={<Avatar>{tagId[0]}</Avatar>}
                    label={tagId.substring(1)}
                  />
                ))}
              </Typography>
            </Paper>
          </Toolbar>
        ) : (
          <></>
        )}
        <Menu
          id="select-context-menu"
          anchorEl={contextMenuAnchor}
          keepMounted
          open={Boolean(contextMenuAnchor)}
          onClose={handleClose}
        >
          {allcontexts.map((context) => (
            <MenuItem onClick={() => selectContext(context)}>{context.value}</MenuItem>
          ))}
          {/* <MenuItem onClick={showCreateNewContext}>Add New...</MenuItem> */}
        </Menu>

        <Dialog
          id="new-context-dialog"
          open={newContextDialog}
          onClose={handleNewContextClose}
          aria-labelledby="form-dialog-title"
          scroll="paper"
        >
          <DialogTitle id="form-dialog-title">Add New NoteBrook</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Name your new NoteBrook. Each NoteBrook completely separates a set of notes, INCLUDING search. Think of a
              NoteBrook like a separate physical notebook. It is meant to help you keep separate notes separate (for
              example, Personal and Work). If you are trying to track and group notes for organization, use nested
              Topics instead of separate NoteBrooks.
            </DialogContentText>
            <TextField
              autoFocus
              value={newContextName}
              onChange={handleNewContextNameChange}
              margin="dense"
              id="context-name"
              label="Context Name"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewContextClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => createNewContext()} color="primary">
              Create Context
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    </>
  );
};
