import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'block',
      justifyContent: 'left',
    },
    contextDropdown: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      // '&:hover': {
      //   backgroundColor: fade(theme.palette.common.white, 0.25),
      // },
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      fontSize: theme.typography.pxToRem(16),
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      // transition: theme.transitions.create('width'),
      '&:focus': {
        width: '38ch',
        margin: 'auto',
        display: 'block',
        flexGrow: 1,
      },

      [theme.breakpoints.down('sm')]: {
        '&:focus': {
          width: '26ch',
          margin: 'auto',
          display: 'block',
          flexGrow: 1,
        },
      },
    },
    toolbar: theme.mixins.toolbar,
    drawer: {},
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      //   flexGrow: 1,
      //   padding: theme.spacing(3),
    },

    heading: {
      fontSize: theme.typography.pxToRem(16),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    panel: {
      minHeight: 40,
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },

    typography: {
      padding: theme.spacing(2),
    },
    searchFocused: {
      width: '400px',
      // borderBottomLeftRadius: 0,
      // borderBottomRightRadius: 0,
      [theme.breakpoints.down('sm')]: {
        width: '300px',
      },
    },
    searchDropdown: {
      zIndex: 9999,
      borderColor: 'blue',
      fontSize: theme.typography.pxToRem(16),
      width: '400px',
      marginTop: 2,
      [theme.breakpoints.down('sm')]: {
        width: '300px',
      },
    },
  }),
);
