import { createSelector } from 'reselect';
import { RootState } from 'features/rootReducer';

/* Memoized selector functions */

// Settings
export const getIsDarkMode = createSelector(
  (state: RootState) => state.app.settings?.darkMode ?? false,
  (selection) => selection,
);
export const getIsAuthenticated = createSelector(
  (state: RootState) => state.app.isAuthenticated,
  (selection) => selection,
);

export const getIsLoggingIn = createSelector(
  (state: RootState) => state.app.isProcessing,
  (selection) => selection,
);

export const getIsSigningUpOrLoggingIn = createSelector(
  (state: RootState) => state.app.isSigningUpOrLoggingIn,
  (selection) => selection,
);

export const getProcessingError = createSelector(
  (state: RootState) => state.app.processingError,
  (selection) => selection,
);
export const getIsSignupOpen = createSelector(
  (state: RootState) => state.app.isSignupOpen,
  (selection) => selection,
);

export const getSearchParameters = createSelector(
  (state: RootState) => state.app.search,
  (selection) => selection,
);

export const getSearchActive = createSelector(
  (state: RootState) => state.app.search.active,
  (selection) => selection,
);

export const getLoginError = createSelector(
  (state: RootState) => state.app.processingError,
  (selection) => (selection !== undefined ? selection : null),
);

export const getIsCommandPalletteActive = createSelector(
  (state: RootState) => state.app.isCommandPalletteActive,
  (selection) => selection,
);

export const getCommandPalletteBaseCommand = createSelector(
  (state: RootState) => state.app.commandPalletteBaseCommand,
  (selection) => selection,
);

/* Memoized selector functions */
export const getActiveNotes = createSelector(
  (state: RootState) => {
    return state.app.activeNotes;
  },
  (selection) => selection,
);

export const getActiveContext = createSelector(
  (state: RootState) => {
    return state.app.activeContext;
  },
  (selection) => selection,
);

export const getAllContexts = createSelector(
  (state: RootState) => {
    return state.app.contexts;
  },
  (selection) => selection,
);

export const getPendingTag = createSelector(
  (state: RootState) => {
    return state.app.pending_tag;
  },
  (selection) => selection,
);
