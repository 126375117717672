import React, { useEffect, useRef, useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ISignupFormProps } from './Signup';
import { useTypedSelector } from 'features/rootReducer';
import { getIsLoggingIn } from 'features/App/appSlice.selectors';
import { useDispatch } from 'react-redux';
import { signupAsync } from 'features/App/appSlice.thunks';

export function SignupForm(props: ISignupFormProps) {
  const { attemptedSubmit, resetAttemptedSubmit } = props;
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const onSubmit = useCallback(
    (data: any) => {
      // Make an ajax call to server and await the response
      dispatch(signupAsync(data.email, data.password, data.fullName, data.promoCode));
    },
    [dispatch],
  );
  useEffect(() => {
    if (attemptedSubmit) {
      resetAttemptedSubmit();
      handleSubmit(onSubmit)();
      //formRef.current?.submit();
    }
  }, [attemptedSubmit, handleSubmit, onSubmit, resetAttemptedSubmit]);

  /*

        [Required]
        [EmailAddress]
        public string Email { get; set; }
        [Required]
        public string Password { get; set; }

        [Required]
        public string Name { get; set; }

        [Required]
        public string PromoCode { get; set; }

        [Required]
        public string ApiVersion { get; set; }
        */
  let passwordErrors = '';
  if (errors.password) {
    switch (errors.password.type) {
      case 'required':
        passwordErrors = 'Password is required.';
        break;
      case 'minLength':
        passwordErrors = 'Password is too short.';
        break;

      case 'maxLength':
        passwordErrors = 'Password is too long.';
        break;
    }
  }

  return (
    <div>
      <Typography>
        Enable real-time syncing to all of your devices and image/file upload (coming soon)
        <br />
      </Typography>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} action="">
        <TextField
          autoComplete="name"
          id="FullName"
          inputRef={register({ required: true, maxLength: 80 })}
          name="fullName"
          error={errors.fullName}
          helperText={
            errors.fullName &&
            (errors.fullName?.type === 'required' ? 'Full Name is required.' : 'Full Name is too long.')
          }
          margin="dense"
          label="Full Name"
          type="text"
          fullWidth
          autoFocus
        />
        <TextField
          autoComplete="email"
          margin="dense"
          inputRef={register({ required: true, pattern: /^\S+@\S+$/i })}
          error={errors.email}
          helperText={
            errors.email &&
            (errors.email?.type === 'required' ? 'Email Address is required.' : 'Email Address is invalid.')
          }
          id="email"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
        />
        <TextField
          autoComplete="new-password"
          margin="dense"
          id="password"
          name="password"
          error={errors.password}
          helperText={passwordErrors}
          label="Password"
          type="password"
          inputRef={register({ required: true, minLength: 8, maxLength: 64 })}
          fullWidth
        />
        <TextField
          autoComplete="off"
          margin="dense"
          id="promoCode"
          name="promoCode"
          error={errors.promoCode}
          helperText={errors.promoCode && 'Early Access Code is required.'}
          label="Early Access Code"
          inputRef={register({ required: true })}
          type="text"
          fullWidth
        />
        <input type="submit" style={{ visibility: 'hidden' }} />
      </form>
    </div>
  );
}
