/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Command, CommandType1 } from 'models/Command';
import { AutocompleteItem } from 'services/SearchService';
import { services } from 'features/App/App';
import { getActiveContext } from 'features/App/appSlice.selectors';
import { useTypedSelector } from 'features/rootReducer';

import throttle from 'lodash/throttle';

export interface ICommandPalletteDialogAutocompleteProps {
  handleAccept: (chosenItem: AutocompleteItem | null) => void;
  command: Command;
}

export default function CommandPalletteDialogAutocomplete(props: ICommandPalletteDialogAutocompleteProps) {
  const { handleAccept, command } = props;
  const [value, setValue] = React.useState<AutocompleteItem | null>(null);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [, toggleOpen] = React.useState(false);
  const context = useTypedSelector(getActiveContext);
  const [options, setOptions] = React.useState<AutocompleteItem[]>([]);
  const [highlightedOption, setHighlightedOption] = React.useState<AutocompleteItem | null>(null);
  // const handleClose = () => {
  //   // setDialogValue({
  //   //   title: '',
  //   //   year: '',
  //   // });
  //   toggleOpen(false);
  // };

  // const updateAutocomplete = (value: string) => {
  //   setInputValue(value);
  //   if (context === undefined) return;
  //   const term = command.commandType1 === CommandType1.TagPeopleSearch ? '@' + value : '#' + value;
  //   services.search.autocompleteTags(context, term).then((result) => {
  //     let matches = result.map((item) => {
  //       return {
  //         description: item.substring(1),
  //         commandType1: item.substring(0, 1) === '@' ? CommandType1.TagPeopleSearch : CommandType1.TagTopicSearch,
  //         existing: true,
  //       };
  //     });
  //     matches.push({ description: inputValue, commandType1: command.commandType1, existing: false });

  //     // return [{ description: inputValue, commandType1: command.commandType1, tagId: '' }];
  //     setOptions(matches);
  //   });
  // };
  // throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
  //   (autocompleteService.current as any).getPlacePredictions(request, callback);
  // }, 200),

  // return [{ description: inputValue, commandType1: command.commandType1, tagId: '' }];
  const getMatches = () => {
    if (context === undefined) return [];
    if (inputValue === '') return [];
    const term = command.commandType1 === CommandType1.TagPeopleSearch ? '@' + inputValue : '#' + inputValue;

    let exactMatch: boolean = false;
    let matches = services.search.autocompleteTags(context, term).map((item) => {
      if (item === term) {
        exactMatch = true;
      }
      return {
        description: item.substring(1),
        commandType1: item.substring(0, 1) === '@' ? CommandType1.TagPeopleSearch : CommandType1.TagTopicSearch,
        existing: true,
      };
    });
    // If there wasn't an exact match to current text, put a "Add ..." option
    if (!exactMatch) {
      matches.push({ description: inputValue, commandType1: command.commandType1, existing: false });
    }

    // return [{ description: inputValue, commandType1: command.commandType1, tagId: '' }];
    return matches;
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 9 && highlightedOption !== null) {
      setInputValue(highlightedOption?.description);
      e.preventDefault();
    }
    // if (e)
  };

  return (
    <React.Fragment>
      <Autocomplete<AutocompleteItem>
        value={value}
        fullWidth={true}
        inputValue={inputValue}
        options={[]}
        filterOptions={() => getMatches()}
        autoHighlight={true}
        onHighlightChange={(event: any, option: AutocompleteItem | null, reason: string) => {
          setHighlightedOption(option);
        }}
        onChange={(event: any, newValue: AutocompleteItem | null) => {
          // if (typeof newValue === 'string') {
          //   // timeout to avoid instant validation of the dialog's form.
          //   setTimeout(() => {
          //     toggleOpen(true);
          //     // setDialogValue({
          //     //   title: newValue,
          //     //   year: '',
          //     // });
          //   });
          //   return;
          // }
          handleAccept(newValue);

          // if (newValue && newValue.inputValue) {
          //   toggleOpen(true);
          //   setDialogValue({
          //     title: newValue.inputValue,
          //     year: '',
          //   });
          //   return;
          // }
        }}
        onInputChange={(event: object, value: string, reason: string) => setInputValue(value)}
        id="command-pallette-dialog-autocomplete"
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // if (option.inputValue) {
          //   return option.inputValue;
          // }
          return option.description;
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(option) => {
          if (!option.existing) {
            return 'Add ' + option.description;
          } else {
            return option.description;
          }
        }}
        freeSolo={false}
        renderInput={(params: any) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return <TextField {...params} autoFocus label="Action Input" variant="outlined" />;
        }}
      />
      {/* <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a new film</DialogTitle>
          <DialogContent>
            <DialogContentText>Did you miss any film in our list? Please, add it!</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.title}
              onChange={(event) => setDialogValue({ ...dialogValue, title: event.target.value })}
              label="title"
              type="text"
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.year}
              onChange={(event) => setDialogValue({ ...dialogValue, year: event.target.value })}
              label="year"
              type="number"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </React.Fragment>
  );
}
