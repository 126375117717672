import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { makeStyles, createStyles, Typography, Card, Theme } from '@material-ui/core';
import { DraftEditor } from './Components/DraftEditor';
import { useTypedSelector } from 'features/rootReducer';
import NoteCard from './Components/NoteCard';

import { getActiveNotes, getSearchParameters, getSearchActive } from 'features/App/appSlice.selectors';
import { AppContext } from 'features/App/App';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    cardWrapper: {
      margin: theme.spacing(1),
    },
    card: {
      maxWidth: 1800,
      margin: 'auto',
    },
    cardBody: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(6),
    },
  }),
);

export default function Notes() {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  let notes = useTypedSelector(getActiveNotes);
  const searchActive = useTypedSelector(getSearchActive);

  useEffect(() => {
    if (searchActive) {
      setTimeout(() => appContext.scrollToTop && appContext.scrollToTop(), 20);
    } else {
      appContext.scrollToBottom && appContext.scrollToBottom();
    }
  }, [appContext, notes, searchActive]);

  return (
    <div className={classes.root}>
      {/* <NoteTwoTone color="primary" className="App-logo" />
       <p>Welcome to NoteBrook!</p>*/}
      {notes.map((note) => (
        <NoteCard note={note} key={note._id} />
      ))}
      {notes.length === 0 && (
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            <div className={classes.cardBody}>
              <Typography variant="h5">There doesn't seem to be anything here...</Typography>
              <Typography variant="h5">Create a new note or expand your search!</Typography>
            </div>
          </Card>
        </div>
      )}
      {/* <ScrollBottom {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowDownIcon />
        </Fab>
      </ScrollBottom> */}

      <DraftEditor placeholder="Add New Note..." />
      {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
    </div>
  );
}
