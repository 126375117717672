import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { getIsCommandPalletteActive, getCommandPalletteBaseCommand } from 'features/App/appSlice.selectors';
import { hideCommandPallette, setPendingTag } from 'features/App/appSlice';
import { useTypedSelector } from 'features/rootReducer';
import CommandPalletteDialogAutocomplete from './CommandPalletteDialogAutocomplete';
import { Command, CommandEmptyCtor, CommandType1 } from 'models/Command';
import { Typography } from '@material-ui/core';
import { AutocompleteItem } from 'services/SearchService';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function CommandPalletteDialog() {
  const dispatch = useDispatch();
  const isCommandPalletteActive = useTypedSelector(getIsCommandPalletteActive);
  // Get base command for command pallette.
  // base command is 'tag-people', 'tag-
  let baseCommand = useTypedSelector(getCommandPalletteBaseCommand);
  if (baseCommand === undefined) {
    baseCommand = CommandEmptyCtor();
  }
  const [item, setItem] = useState<AutocompleteItem | null>(null);
  const [newCommand, setNewCommand] = useState(baseCommand);
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fullScreen = false;
  const handleClose = () => {
    if (baseCommand?.commandType1 === CommandType1.TagPeopleSearch) {
      dispatch(setPendingTag('~CANCELED~@'));
    } else if (baseCommand?.commandType1 === CommandType1.TagTopicSearch) {
      dispatch(setPendingTag('~CANCELED~#'));
    }
    dispatch(hideCommandPallette());
  };
  const handleCommandChange = (command: Command) => {
    dispatch(setNewCommand(command));
  };

  const handleApplyCommand = (item: AutocompleteItem | null) => {
    //dispatch(command)
    dispatch(hideCommandPallette());
    // TODO: pass tag ID as well?
    let tag = '';
    if (item?.commandType1 === CommandType1.TagPeopleSearch) {
      tag = '@' + item?.description ?? '';
    } else if (item?.commandType1 === CommandType1.TagTopicSearch) {
      tag = '#' + item?.description ?? '';
    }
    // else if (item?.matchType === )
    dispatch(setPendingTag(tag));
  };

  let title = 'Quick Actions';
  let helpText = (
    <Typography>
      Create <b>%TODOs</b>, mention <b>@People</b>, tag <b>#Topics</b> and set <b>!Priorities</b> of your notes.
    </Typography>
  );
  switch (baseCommand.commandType1) {
    case CommandType1.TagPeopleSearch:
      title = 'Mention a Person';
      helpText = (
        <Typography>
          Find someone you know, or <b>@tag</b> someone new!
        </Typography>
      );
      break;
    case CommandType1.TagPrioritySearch:
      title = 'Set a Priority';
      helpText = (
        <Typography>
          Choose your <b>!priority</b>...
        </Typography>
      );
      break;
    case CommandType1.TagToDoSearch:
      title = 'Create To-Do';
      helpText = (
        <Typography>
          Create a new <b>%TODO</b>.
        </Typography>
      );
      break;
    case CommandType1.TagTopicSearch:
      title = 'Choose Topic';
      helpText = (
        <Typography>
          Search for a <b>#topic</b>, or Add New... #topics/can have/sub-topics too !
        </Typography>
      );
      break;
  }
  const theme = useTheme();
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isCommandPalletteActive}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        transitionDuration={{ appear: 800, enter: 0, exit: 0 }}
      >
        <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: theme.spacing(4) }}>{helpText}</DialogContentText>
          <CommandPalletteDialogAutocomplete command={baseCommand} handleAccept={handleApplyCommand} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
