import React, { ChangeEvent, useRef, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  MenuItem,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  useTheme,
  Chip,
  Avatar,
  Paper,
  useMediaQuery,
  Popper,
  Fade,
} from '@material-ui/core';
import { KeyboardArrowDown, FormatListBulletedSharp } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from './HeaderStyles';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { useTypedSelector } from 'features/rootReducer';
import {
  getAllContexts,
  getSearchParameters,
  getSearchActive,
  getActiveContext,
} from 'features/App/appSlice.selectors';
import { useDispatch } from 'react-redux';
import { startSearchAsync } from 'features/App/appSlice.thunks';
import { eventNames } from 'cluster';
import { setSearchActive } from 'features/App/appSlice';
import { useHotkeys } from 'react-hotkeys-hook';
import { services } from 'features/App/App';
import { SearchSuggestion } from './Header';

export interface IHeaderSearchProps {
  searchInputRef: any;
}

const noMatch = 'No Matches Found.';
const defaultMessagesArray = ['Enter a name to search.', 'Enter a topic to search.'];

export function HeaderSearch(props: IHeaderSearchProps) {
  const { searchInputRef } = props;

  const activeContext = useTypedSelector(getActiveContext);
  const [searchAnchor, setSearchAnchor] = useState<HTMLButtonElement | null>(null);
  const searchActive = useTypedSelector(getSearchActive);
  const searchParameters = useTypedSelector(getSearchParameters);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestion[]>([]);
  useEffect(() => {
    if (!searchActive) {
      setSearchSuggestions([]);
      return;
    }
    // Only show tooltip if there's no current search and no tags either.
    // if someone already has tags, then the dropdown overlays the tags, and
    // they probably don't need to see the help text anyway.
    if (searchValue === '' && searchParameters.tag_ids.length === 0) {
      setSearchSuggestions([
        {
          value: '@',
          description: '@ People',
          onClick: () => {
            alert('people');
          },
        },
        { value: '#', description: '# Topics', onClick: () => {} },
      ]);
      return;
    }
    let defaultMessages: Record<string, string> = {
      '@': defaultMessagesArray[0],
      '#': defaultMessagesArray[1],
    };
    setSelectedAutocompleteIndex(0);
    for (var key in defaultMessages) {
      if (defaultMessages.hasOwnProperty(key)) {
        if (searchValue === key || !activeContext) {
          setSearchSuggestions([{ value: defaultMessages[key], onClick: () => {} }]);
          return;
        }
        if (searchValue.startsWith(key)) {
          let results = services.search.autocompleteTags(activeContext, searchValue);
          // get results that are not in our list already.
          let filteredResults = results
            .filter((x) => !searchParameters.tag_ids.includes(x))
            .map((result) => {
              return { value: result, description: result.substr(1, result.length), onClick: () => {} };
            });
          if (filteredResults.length === 0) {
            // if no results left, let user know
            setSearchSuggestions([{ value: noMatch, onClick: () => {} }]);
          } else {
            setSearchSuggestions(filteredResults);
          }

          return;
        }
      }
    }

    setSearchSuggestions([]);
  }, [activeContext, searchActive, searchParameters.tag_ids, searchValue]);

  const searchFocusChanged = (active: boolean) => {
    dispatch(setSearchActive(active));
  };

  const dispatch = useDispatch();

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value;
    setSearchValue(value);
    if (!value.startsWith('@') && !value.startsWith('#')) {
      dispatch(startSearchAsync({ ...searchParameters, term: event.target.value }));
    }
  };

  const handleAutocompleteItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedAutocompleteIndex(index);
  };

  const [selectedAutocompleteIndex, setSelectedAutocompleteIndex] = React.useState(0);

  const classes = useStyles();
  return (
    <>
      <div className={classes.search + (searchActive ? ' ' + classes.searchFocused : '')}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          inputRef={searchInputRef}
          placeholder="Find Anything"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 27) {
              // Escape
              searchInputRef.current?.blur();
            } else if (event.keyCode === 38) {
              // Up Arrow
              setSelectedAutocompleteIndex(
                selectedAutocompleteIndex > 0 ? selectedAutocompleteIndex - 1 : selectedAutocompleteIndex,
              );
              event.preventDefault();
            } else if (event.keyCode === 40) {
              // Down Arrow
              setSelectedAutocompleteIndex(
                selectedAutocompleteIndex < searchSuggestions.length - 1
                  ? selectedAutocompleteIndex + 1
                  : selectedAutocompleteIndex,
              );
              event.preventDefault();
            } else if (event.keyCode === 9) {
              // Tab
              if (searchSuggestions && searchSuggestions[selectedAutocompleteIndex]) {
                setSearchValue(searchSuggestions[selectedAutocompleteIndex].value);
              }
              event.preventDefault();
            } else if (event.keyCode === 13) {
              const isValid = (param: SearchSuggestion) => {
                if (!param) return false;
                if (param.value === noMatch) return false;
                if (defaultMessagesArray.includes(param.value)) return false;
                return true;
              };
              // Enter
              if (searchSuggestions && searchSuggestions[selectedAutocompleteIndex]) {
                if (searchSuggestions[selectedAutocompleteIndex].value === '@') {
                  setSearchValue('@');
                } else if (searchSuggestions[selectedAutocompleteIndex].value === '#') {
                  setSearchValue('#');
                } else if (isValid(searchSuggestions[selectedAutocompleteIndex])) {
                  setSearchValue('');
                  dispatch(
                    startSearchAsync({
                      ...searchParameters,
                      term: '',
                      tag_ids: [...searchParameters.tag_ids, searchSuggestions[selectedAutocompleteIndex].value],
                    }),
                  );
                }
              } else {
                searchInputRef?.current?.blur();
              }
            }
          }}
          onFocus={() => {
            searchFocusChanged(true);
            searchInputRef.current?.setSelectionRange(0, searchInputRef.current?.value.length, 'forward');
          }}
          onBlur={() => searchFocusChanged(false)}
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onChange={handleSearchInputChange}
        />
      </div>
      <Popper
        open={searchActive && searchSuggestions.length > 0}
        anchorEl={searchInputRef.current}
        placement={'bottom'}
        className={classes.searchDropdown}
        onClick={() => alert('here2')}
        // onClose={() => alert('here')}
        // transition
      >
        <Paper>
          {searchValue.length === 0 && (
            <Typography className={classes.typography}>Type to search, filter with @ or #.</Typography>
            // <Typography className={classes.typography}>Type to search, filter with @, #, ! or %.</Typography>
          )}
          {searchSuggestions.map((item, index) => (
            <div>
              <MenuItem selected={selectedAutocompleteIndex === index} onClick={(e) => item.onClick(e)}>
                {item.description ? item.description : item.value}
              </MenuItem>
            </div>
          ))}
        </Paper>
      </Popper>
    </>
  );
}
