import { PouchNote } from 'services/PersistenceService/models/PouchNote';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { SearchParameters } from 'features/App/appSlice.state';
import FlexSearch, { Index } from 'flexsearch';
import { CommandType1 } from 'models/Command';
import _ from 'lodash';

// export interface AutocompletePersonItem {
//   tagId: string;
//   matchType: 'Person';
//   description: string;
// }

// export interface AutocompleteItem {
//   matchType: 'Person' | 'Topic' | 'Priority';
//   tagId: string;
//   description: string;
// }
// export type AutocompleteItem = AutocompleteItem | AutocompletePersonItem;

export interface AutocompleteItem {
  commandType1: CommandType1;
  existing: boolean;
  description: string;
}
/* Search Parameters

  term: string;
  loading: boolean;
  tag_ids: string[];
  favorites_only: boolean;
  include_deleted: boolean;
  raw_results: string[];

  */

export interface ISearchService {
  autocompleteTags: (context: PouchContext, query: string) => string[];
  updateNote: (context: PouchContext, note: PouchNote) => void;
  //   updateTag: async (context: PouchContext, notes: PouchNote[], contexts: PouchContext[]) => void;
  rebuildIndexForContext(context: PouchContext, notes: PouchNote[]): void;
  resetIndexes(): void;
  searchNotes: (context: PouchContext, parameters: SearchParameters) => Promise<string[]>;
  // setSearch: (parameters: SearchParameters) => void;
}

export function CheckFilters(parameters: SearchParameters, note: PouchNote | SearchNote) {
  if (parameters.favorites_only && !note.favorite) return false;
  if (!parameters.include_deleted && note.deleted) return false;
  // This is an OR match.
  /*if (parameters.tag_ids.length > 0 && !parameters.tag_ids.some((tag) => note.tags.includes(tag))) {
    return false;
  }*/
  // This is an AND match.
  for (let param of parameters.tag_ids) {
    if (!note.tags.includes(param)) {
      return false;
    }
  }
  return true;
}

export type SearchTag = {
  id: string;
  result: string;
};

export type SearchNote = {
  id: number;
  tags: string[];
  favorite: boolean;
  deleted: boolean;
  text: string;
};

export type SearchServiceState = {
  noteIdList: string[];
  indexesByContext: Record<
    string,
    {
      tagIndex: SearchTag[];
      noteIndex: Index<SearchNote>;
    }
  >;
};

/* Automatically set compaction to true */
export default class SearchService implements ISearchService {
  private state: SearchServiceState;

  constructor() {
    this.state = {
      noteIdList: [],
      indexesByContext: {},
    };
  }
  private count = (str: string, ch: string) => _.countBy(str)[ch] || 0;
  public autocompleteTags(context: PouchContext, query: string) {
    // let resultIds: number[] = await this.state.indexesByContext[context._id].tagIndex.search(query, 20);
    // let results = resultIds.map((resultId) => this.state.tagIdList[resultId]);
    if (!this.state.indexesByContext[context._id] || !this.state.indexesByContext[context._id].tagIndex) {
      return [];
    }
    const lowerQuery = query.toLowerCase();
    let results: string[] = [];
    let colonLowerResults: string[] = [];
    let queryNestingCount = this.count(query, '/');

    for (let item of this.state.indexesByContext[context._id].tagIndex) {
      if (results.length > 30) {
        // Allow more results than max result set, so after sort the results will be more relevant.
        break;
      }
      if (item.id.startsWith(lowerQuery)) {
        // Match up to the same # of / + 1 as the query.  If there are more / then collapse matches.
        let prefixNestingCountMatch = '';
        let prefixNestingCount = 0;
        for (let char of item.result) {
          if (char === '/') {
            prefixNestingCount += 1;
          }
          prefixNestingCountMatch += char;
          if (prefixNestingCount > queryNestingCount) {
            break;
          }
        }
        let prefixNestingCountMatchLower = prefixNestingCountMatch.toLowerCase();
        if (colonLowerResults.indexOf(prefixNestingCountMatchLower) === -1) {
          results.push(prefixNestingCountMatch);
          colonLowerResults.push(prefixNestingCountMatchLower);
        }
      }
    }
    // Sort alphabetically
    results.sort((a, b) => a.localeCompare(b));

    return _.take(results, 15);
  }

  public async searchNotes(context: PouchContext, parameters: SearchParameters) {
    if (!this.state.indexesByContext[context._id] || !this.state.indexesByContext[context._id].noteIndex) {
      return [];
    }
    // Get all search names
    // TODO: find a quicker way to do this using flexsearch natively? or at least use paged results?
    // TODO: if no term is issued, should we return all notes that match filter?
    let allMatches = await this.state.indexesByContext[context._id].noteIndex.search(parameters.term);
    let filteredMatches = allMatches.filter((note) => CheckFilters(parameters, note));
    // Convert filtered match IDs to original note IDs.
    let ids = filteredMatches.map((match) => this.state.noteIdList[match.id]);
    return ids;
  }

  public async updateNote(context: PouchContext, note: PouchNote) {
    // if the note is not currently in our global note list, add it.
    let targetNoteId = this.state.noteIdList.indexOf(note._id);
    let shouldUpdate = false;
    if (targetNoteId === -1) {
      this.state.noteIdList.push(note._id);
      targetNoteId = this.state.noteIdList.length - 1;
    } else {
      // update in index since it existed in index already.
      shouldUpdate = true;
    }

    // Create an indexed version of the note
    let indexableNote: SearchNote = {
      id: targetNoteId,
      tags: note.tags,
      favorite: note.favorite,
      deleted: note.deleted,
      text: note.text,
    };

    if (!this.state.indexesByContext[context._id]) {
      this.rebuildIndexForContext(context, []);
    }

    if (shouldUpdate) {
      this.state.indexesByContext[context._id].noteIndex.remove(targetNoteId);
      this.state.indexesByContext[context._id].noteIndex.add(indexableNote);
    } else {
      this.state.indexesByContext[context._id].noteIndex.add(indexableNote);
    }
    // TODO: update all the tags as well.
    // insert into index since it didn't exist yet.
    // Get all our tag strings mapped to tag IDs
    for (let tag of note.tags) {
      let newTagId = tag.toLowerCase();
      let targetTagId = _.findIndex(this.state.indexesByContext[context._id].tagIndex, function (o) {
        return o.id === newTagId;
      });
      let shouldUpdate = false;
      if (targetTagId === -1) {
        this.state.indexesByContext[context._id].tagIndex.push({ id: newTagId, result: tag });
        targetTagId = this.state.indexesByContext[context._id].tagIndex.length - 1;
      } else {
        // update in index since it existed in index already.
        shouldUpdate = true;
      }

      // // Create an indexed version of the tag
      // let indexableTag: SearchTag = {
      //   id: targetNoteId,
      //   tags: note.tags,
      //   favorite: note.favorite,
      //   deleted: note.deleted,
      //   text: note.text,
      // };
      if (shouldUpdate) {
        // There's not really an update to tags... so don't do anything here.
        // this.state.indexesByContext[context._id].tagIndex.remove(targetTagId);
        // this.state.indexesByContext[context._id].tagIndex.add(targetTagId, tag);
      } else {
        // Commenting this out because we're not using it for now.
        //this.state.indexesByContext[context._id].tagIndex.add(targetTagId, tag);
      }
    }
  }

  public async rebuildIndexForContext(context: PouchContext, notes: PouchNote[]) {
    // Create a separate index for the given context.
    const noteIndex = FlexSearch.create<SearchNote>({
      doc: {
        id: 'id',
        field: ['text'],
      },
    });
    this.state.indexesByContext[context._id] = { tagIndex: [], noteIndex: noteIndex };
    // Update every note so all notes / tags are added
    for (let note of notes) {
      await this.updateNote(context, note);
    }
  }
  public async resetIndexes() {
    this.state.noteIdList = [];
    this.state.indexesByContext = {};
  }
  setSearch = (parameters: SearchParameters) => {};
}

/* 

export const searchThunk = (query: string, tag_ids: string[]): AppThunk => async (dispatch, getState) => {
  dispatch(actions.searchStart({ term: query, tag_ids: tag_ids }));
  const raw_results = NoteIndex.search(query).map((result) => result.ref);
  // const notes = getState().notes.notes.keys.filter(note =>
  //   )
  // set notes results
  // Search all Notes that are in our current context
  // and have matching tags set.

  // First find all notes matching the query
  // Then matching the context
  // then matching the tags
  // TODO: make this more efficient if needed.
  let notesArray: PouchNote[] = [];
  const notes = getState().notes.activeNotes;
  for (var key in notes) {
    // check if the property/key is defined in the object itself, not in parent
    if (notes.hasOwnProperty(key)) {
      notesArray.push(notes[key]);
    }
  }
  dispatch(actions.searchComplete(notesArray));
};
*/
