// export type TagCollection = Record<Guid, Tag>;
export enum TagType {
  Generic = 1,
  Topic = 101,
  People = 201,
  Priority = 301,
  ToDo = 401,
}

export const TagTypeDescriptor: Record<TagType, string> = {
  [TagType.Generic]: 'Generic',
  [TagType.Topic]: 'Topic',
  [TagType.People]: 'People',
  [TagType.Priority]: 'Priority',
  [TagType.ToDo]: 'To-Do',
};

export const TagTypeShortDescriptor: Record<TagType, string> = {
  [TagType.Generic]: 'g',
  [TagType.Topic]: 't',
  [TagType.People]: 'p',
  [TagType.Priority]: 'r',
  [TagType.ToDo]: 'd',
};

// export class Tag {
//   Id: Guid;
//   PouchId: string;
//   Heirarchy: Guid[];
//   Type: TagType;
//   Value: string;
// }

// export type ProjectTag = Tag & {
//   Type: TagType.Project;
// };
// export type PeopleTag = Tag & {
//   Type: TagType.People;
// };
// export type PriorityTag = Tag & {
//   Type: TagType.Priority;
// };
// export type ToDoTag = Tag & {
//   Type: TagType.ToDo;
// };
