import { NoteType, NoteTypeShortDescriptor } from 'services/PersistenceService/models/Notes';
import { getNewShorterShortId } from 'models/ShortId';
import { getCurrentShortDate } from 'models/ShortDate';
import { ContextIdFromDBKey, PouchContext } from './PouchContext';

export interface PouchNote {
  _id: string;
  type: NoteType;
  tags: string[];
  attachments: string[];
  text: string;
  html: string;
  delta: string;
  favorite: boolean;
  deleted: boolean;
  created: number;
  updated: number;
  metadata: object;
}

export const OnboardingNote: PouchNote = {
  _id: 'n!Personal!1590887373012!g!t8UKWThj',
  created: 1590887373012,
  updated: 1590887373012,
  text:
    '🎉 Welcome to NoteBrook! 🥳\nInstant Sync to all devices (30-second signup, use the menu top-left ☝🏽)\nWork Anywhere, online or offline\n people and reference  for easy retrieval\nFind and Filter notes immediately, anytime\nCreate a new note below to get started 👇🏽\nCrafted with 🥯 +☕ +🧡 in Austin, Texas',
  delta:
    '{"ops":[{"insert":"🎉 Welcome to NoteBrook! 🥳"},{"attributes":{"header":1},"insert":"\\n"},{"attributes":{"bold":true},"insert":"Instant Sync"},{"insert":" to all devices (30-second signup, use the menu top-left ☝🏽)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"attributes":{"bold":true},"insert":"Work Anywhere"},{"insert":", online or offline"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":{"tag":{"character":"@","body":"mention"}}},{"insert":" people and reference "},{"insert":{"tag":{"character":"#","body":"topics"}}},{"insert":" for easy retrieval"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"attributes":{"bold":true},"insert":"Find "},{"insert":"and"},{"attributes":{"bold":true},"insert":" Filter"},{"insert":" notes immediately, anytime"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Create a new note below to get started 👇🏽"},{"attributes":{"header":2},"insert":"\\n"},{"attributes":{"italic":true},"insert":"Crafted with 🥯 +☕ +🧡 by Luke Paireepinart"},{"insert":"\\n"}]}',
  html:
    '<h2>🎉 Welcome to NoteBrook! 🥳</h2><ul><li><strong>Instant Sync</strong> to all devices (30-second signup, use the menu top-left ☝🏽)</li><li><strong>Work Anywhere</strong>, online or offline</li><li><span class="ql-notebrook-tag" data-character="@" data-body="mention">\ufeff<span contenteditable="false"><span><div class="MuiChip-root MuiChip-colorPrimary MuiChip-outlined MuiChip-outlinedPrimary"><div class="MuiAvatar-root MuiAvatar-circle MuiChip-avatar MuiChip-avatarColorPrimary MuiAvatar-colorDefault">@</div><span class="MuiChip-label">mention</span></div></span></span>\ufeff</span> people and reference <span class="ql-notebrook-tag" data-character="#" data-body="topics">\ufeff<span contenteditable="false"><span><div class="MuiChip-root MuiChip-colorSecondary MuiChip-outlined MuiChip-outlinedSecondary"><div class="MuiAvatar-root MuiAvatar-circle MuiChip-avatar MuiChip-avatarColorSecondary MuiAvatar-colorDefault">#</div><span class="MuiChip-label">topics</span></div></span></span>\ufeff</span> for easy retrieval</li><li><strong>Find </strong>and<strong> Filter</strong> notes immediately, anytime</li></ul><h2>Create a new note below to get started 👇🏽</h2><div><em>Crafted with 🧡 + 🥯 + ☕ in Austin, Texas</em></div>',
  favorite: false,
  type: 1,
  deleted: false,
  tags: ['@mention', '#topics'],
  attachments: [],
  metadata: {},
};

export const PouchNoteCtor = (
  context: PouchContext,
  type: NoteType,
  tags: string[],
  attachments: string[],
  text: string,
  html: string,
  delta: string,
  metadata: any,
): PouchNote => {
  let created = getCurrentShortDate();
  let typeString = NoteTypeShortDescriptor[type];
  return {
    _id:
      'n!' +
      ContextIdFromDBKey(context._id) +
      '!' +
      created.toString() +
      '!' +
      typeString +
      '!' +
      getNewShorterShortId(),
    created: created,
    updated: created,
    text: text,
    delta: delta,
    html: html,
    favorite: false,
    type: type,
    deleted: false,
    tags: tags,
    attachments: attachments,
    metadata: metadata,
  };
};

// export const PouchNoteFromDoc = (doc: any) : PouchNote =>
// {
//   return {
//   context: doc.context,
//   type: doc.type,
//   tags: string[],
//   body: string,
//   html: string,
//   metadata: any,
//   }
// }
