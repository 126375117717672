import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAppSliceState, SearchParameters } from 'features/App/appSlice.state';
import { LoginResponse, SignupResponse } from 'api/types';
import { Command } from 'models/Command';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { PouchNote } from 'services/PersistenceService/models/PouchNote';
import { PouchSettings } from 'services/PersistenceService/models/PouchSettings';

const appSlice = createSlice({
  name: 'app',
  initialState: initialAppSliceState,
  reducers: {
    showCommandPallette(state, action: PayloadAction<Command>) {
      state.commandPalletteBaseCommand = action.payload;
      state.isCommandPalletteActive = true;
    },
    hideCommandPallette(state, action: PayloadAction) {
      state.isCommandPalletteActive = false;
    },

    loginStart(state) {
      state.isProcessing = true;
      state.processingError = undefined;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      if (action.payload.type !== 'success') {
        return;
      }
      state.isProcessing = false;
      state.isSignupOpen = false;
      state.isAuthenticated = true;
      state.processingError = undefined;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.isProcessing = false;
      state.isAuthenticated = false;
      state.processingError = action.payload;
    },
    clearProcessingError(state, action: PayloadAction) {
      state.processingError = undefined;
    },
    signupStart(state) {
      state.isProcessing = true;
      state.processingError = undefined;
    },
    signupSuccess(state, action: PayloadAction<SignupResponse>) {
      if (action.payload.type !== 'success') {
        return;
      }
      state.isProcessing = false;
      state.isSignupOpen = false;
      state.isAuthenticated = true;
      state.processingError = undefined;
    },
    signupFailed(state, action: PayloadAction<string>) {
      state.isProcessing = false;
      state.isAuthenticated = false;
      state.processingError = action.payload;
    },
    resumeSession(state, action: PayloadAction) {
      state.isAuthenticated = true;
    },
    logoutStart(state) {
      state.isProcessing = true;
      state.isAuthenticated = false;
    },
    setSignupOpen(state, action: PayloadAction<boolean>) {
      if (action.payload === true) {
        state.isProcessing = false;
        state.processingError = '';
      }
      state.isSignupOpen = action.payload;
    },
    setIsSigningUpOrLoggingIn(state, action: PayloadAction<boolean>) {
      state.isSigningUpOrLoggingIn = action.payload;
    },
    setSearchActive(state, action: PayloadAction<boolean>) {
      state.search.active = action.payload;
    },
    setNotes(state, action: PayloadAction<PouchNote[]>) {
      state.activeNotes = action.payload;
    },
    setActiveContext(state, action: PayloadAction<PouchContext>) {
      state.activeContext = action.payload;
    },
    setContexts(state, action: PayloadAction<PouchContext[]>) {
      state.contexts = action.payload;
    },
    setSettings(state, action: PayloadAction<PouchSettings>) {
      state.settings = action.payload;
    },
    setPendingTag(state, action: PayloadAction<string>) {
      state.pending_tag = action.payload;
    },
    updateNote() {
      // TODO: rev note somehow? use pouchdb-upsert?
      //console.log('Update note called');
    },
    searchStart(state, action: PayloadAction<SearchParameters>) {
      state.search = { ...action.payload, loading: true };
    },
    /* Search Reducers */
    // searchRawResults(state, action: PayloadAction<string[]>)
    // {
    //   state.search.raw_results = action.payload;
    //   getMatchingNotes(state);
    // },
    searchComplete(state, action: PayloadAction<PouchNote[]>) {
      state.search.loading = false;
      state.activeNotes = action.payload;
    },
  },
});

// export default db;

const { actions, reducer } = appSlice;

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  clearProcessingError,
  signupStart,
  signupSuccess,
  signupFailed,
  logoutStart,
  showCommandPallette,
  hideCommandPallette,
  setNotes,
  setSettings,
  setSignupOpen,
  setContexts,
  setPendingTag,
  setActiveContext,
  setIsSigningUpOrLoggingIn,
  searchStart,
  searchComplete,
  resumeSession,
  setSearchActive,
} = actions;
export default reducer;
