import { Switch, Route } from 'react-router-dom';
import React from 'react';
import Notes from 'features/Notes/Notes';
import ToDo from 'features/Tags/ToDo';
import { People } from 'features/Tags/People';
import { Topics } from 'features/Tags/Topics';
import { Contexts } from 'features/Tags/Contexts';
import { Search } from 'features/Search/Search';
export const Routes = () => (
  <Switch>
    <Route key={0} exact path="/">
      <Notes />
    </Route>
    <Route key={1} path="/notes">
      <Notes />
    </Route>
    <Route key={2} path="/search">
      <Search />
    </Route>
    <Route key={3} path="/contexts">
      <Contexts />
    </Route>
    <Route key={4} path="/topics">
      <Topics />
    </Route>
    <Route key={5} path="/people">
      <People />
    </Route>
    <Route key={6} path="/todo">
      <ToDo />
    </Route>
  </Switch>
);
