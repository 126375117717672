import React, { useState } from 'react';
import {
  Divider,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Switch,
  ListItemSecondaryAction,
  Toolbar,
  makeStyles,
  Theme,
  createStyles,
  Zoom,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import ListItemLink from 'features/Shared/Components/ListItemLink';
import { useDispatch } from 'react-redux';
import { MenuItems } from './MenuItems';
import { Brightness4, PersonAdd, ExitToApp, LockOpen } from '@material-ui/icons';
import { getIsDarkMode, getIsAuthenticated } from 'features/App/appSlice.selectors';
import { useTypedSelector } from 'features/rootReducer';
import { toggleDarkModeAsync, logoutAsync } from 'features/App/appSlice.thunks';
import Signup from 'features/Signup/Signup';
import { setSignupOpen, setIsSigningUpOrLoggingIn } from 'features/App/appSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cta: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

export interface ISideMenuListProps {
  visible: boolean;
  handleDrawerToggle: () => void;
}

export function SideMenuList(props: ISideMenuListProps) {
  const { visible, handleDrawerToggle } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDarkMode = useTypedSelector(getIsDarkMode);
  const isAuthenticated = useTypedSelector(getIsAuthenticated);

  const handleDarkModeChange = () => {
    dispatch(toggleDarkModeAsync());
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };
  const handleSignupClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setIsSigningUpOrLoggingIn(true));
    dispatch(setSignupOpen(true));
    handleDrawerToggle();
  };

  const handleLoginClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setIsSigningUpOrLoggingIn(false));
    dispatch(setSignupOpen(true));
    handleDrawerToggle();
  };

  const handleLogoutClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(logoutAsync());
    handleDrawerToggle();
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  return (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap>
          Menu
        </Typography>
      </Toolbar>
      <Divider />
      {isAuthenticated ? (
        <ListItem button onClick={(event: any) => handleLogoutClick(event)}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      ) : (
        <>
          <List>
            <Zoom in={visible} timeout={{ enter: 300, exit: 200 }}>
              <ListItem button className={classes.cta} onClick={(event: any) => handleSignupClick(event)}>
                <ListItemIcon className={classes.cta}>
                  <PersonAdd />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.cta, secondary: classes.cta }}
                  primary="Register for Free"
                  secondary={'Enable Sync'}
                />
              </ListItem>
            </Zoom>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={(event: any) => handleLoginClick(event)}>
              <ListItemIcon>
                <LockOpen />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItem>
          </List>
        </>
      )}
      <Divider />
      {/* <List>
        {MenuItems.map((menuOption, index) => (
          <ListItemLink
            to={menuOption.to}
            key={index}
            selected={selectedIndex === index}
            onClick={(event: any) => handleListItemClick(event, index)}
            primary={menuOption.primary}
            icon={menuOption.icon}
          />
        ))}
      </List> */}
      {/* <Divider /> */}
      {/* <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      <Divider />
      {/* <List>
        <ListItem button key="darkmode" onClick={() => handleDarkModeChange()}>
          <ListItemIcon>
            <Brightness4 />
          </ListItemIcon>
          <ListItemText id="switch-list-label-darkMode" primary={isDarkMode ? 'Dark' : 'Light'} />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onClick={() => handleDarkModeChange()}
              checked={isDarkMode}
              inputProps={{ 'aria-labelledby': 'switch-list-label-darkMode' }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List> */}
    </div>
  );
}
