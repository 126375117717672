import React from 'react';
import { useStyles } from './MainStyles';
import { SideMenu } from './Components/SideMenu';
import { Routes } from './Components/Routes';
import { CommandPalletteDialog } from 'features/Shared/Components/CommandPalletteDialog';

import { useHotkeys } from 'react-hotkeys-hook';
import { Header } from './Components/Header';
import { useTypedSelector } from 'features/rootReducer';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { useDispatch } from 'react-redux';
import { getActiveContext } from 'features/App/appSlice.selectors';
import { setActiveContextAsync } from 'features/App/appSlice.thunks';

export default function ResponsiveDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeContext = useTypedSelector(getActiveContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // useHotkeys('/', () => alert('/ hotkey'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeContext = (context: PouchContext) => {
    dispatch(setActiveContextAsync(context._id));
  };
  const search = () => {
    alert('search');
  };

  return (
    <div className={classes.root}>
      <CommandPalletteDialog />
      <Header
        changeContext={changeContext}
        search={search}
        currentContextName={activeContext?.value ?? 'Loading'}
        drawerToggle={handleDrawerToggle}
      />
      <SideMenu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}></SideMenu>
      <main className={classes.content}>
        <Routes />
      </main>
    </div>
  );
}
