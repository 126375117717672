import { AppConfig } from './AppConfig';

export const prodConfig: AppConfig = {
  stripePublishableKey: '',
  stripeMonthlyPlanId: '',
  stripeAnnualPlanId: '',
  stripeSuccessUrl: '',
  stripeFailureUrl: '',
  apiLoginUrl: 'https://api.notebrook.app/auth/login',
  apiSignupUrl: 'https://api.notebrook.app/signup',
  apiVersion: '1.0.0',
};
