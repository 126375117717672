import React from 'react';
import { Typography } from '@material-ui/core';
import { TagType, TagTypeDescriptor } from 'services/PersistenceService/models/Tags';

export interface ITagsProps {
  tagType: TagType;
}

export function Tags(props: ITagsProps) {
  const { tagType } = props;
  return (
    <div>
      All {TagTypeDescriptor[tagType]}s TODO: Render cards here.
      <Typography>Add New {TagTypeDescriptor[tagType]}</Typography>
    </div>
  );
  // Form for creating new
}
