import { combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import appReducer from './App/appSlice';

const rootReducer = combineReducers({ app: appReducer });
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
