import PersistenceService from 'services/PersistenceService';
import SearchService from 'services/SearchService';

export interface IServiceCoordinator {}

export type ServiceCoordinatorState = {
  persistenceService: PersistenceService;
  searchService: SearchService;
};

/* Automatically set compaction to true */
class ServiceCoordinator implements IServiceCoordinator {
  private state: ServiceCoordinatorState;
  get search(): SearchService {
    return this.state.searchService;
  }
  get persistence(): PersistenceService {
    return this.state.persistenceService;
  }
  constructor() {
    // TODO: DI here.
    const searchService = new SearchService();
    const persistenceService = new PersistenceService(searchService);
    this.state = {
      searchService: searchService,
      persistenceService: persistenceService,
    };
  }
}

// interface ServiceCoordinator extends PersistenceService, SearchService {}
// applyMixins(ServiceCoordinator, [PersistenceService, SearchService]);
export default ServiceCoordinator;
