import React, { useState, useRef, MutableRefObject, useEffect, useCallback, useContext } from 'react';
import ReactQuill from 'react-quill'; // ES6
import { useDispatch } from 'react-redux';
import { showCommandPallette, setPendingTag } from 'features/App/appSlice';
import { NoteType } from 'services/PersistenceService/models/Notes';
import { Card, CardContent } from '@material-ui/core';
import { PouchNote, PouchNoteCtor } from 'services/PersistenceService/models/PouchNote';
import _ from 'lodash';
import { CommandEmptyCtor, CommandType1, CommandTypedCtor } from 'models/Command';
import { useTypedSelector } from 'features/rootReducer';
import { services, AppContext } from 'features/App/App';
import { getActiveContext, getPendingTag } from 'features/App/appSlice.selectors';
import { setDraftNoteAsync } from 'features/App/appSlice.thunks';
import './DraftEditor.scss';
import { useStyles } from './DraftEditorStyles';
import 'helpers/TagBlot';
import { TagEmbed } from 'helpers/TagBlot';

interface IEditorProps {
  placeholder: string;
}

export interface IEditorData {
  text: string;
  tagList: string[];
  selection: ReactQuill.Range;
  selectedText: string;
  prefixText: string;
  suffixText: string;
}

// type EditorState = {
//   editorHtml: string;
//   theme: string | undefined;
//   notes: string[];
// };

export function DraftEditor(props: IEditorProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeContext = useTypedSelector(getActiveContext);
  const [editorValue, setEditorValue] = useState('');
  const [selection, setEditorSelection] = useState<ReactQuill.Range>(null);

  const pendingTag = useTypedSelector(getPendingTag);
  useEffect(() => {
    // console.log('processing pending tag: ' + pendingTag);
    if (!editorRef.current || pendingTag === '') return;
    // if (range) {
    //@ts-ignore
    var range = selection; //editorRef.current.getEditor().getSelection();
    // console.log('with selection: ' + range);
    if (range) {
      // console.log(pendingTag);
      if (pendingTag.startsWith('~CANCELED~')) {
        // restore selection position but don't insert embed.
        editorRef.current.getEditor().setSelection(range.index, 0, 'user');
        editorRef.current.getEditor().insertText(range.index, pendingTag[10], 'user');
        editorRef.current.getEditor().setSelection(range.index + 1, 0, 'user');
      } else {
        let tag: TagEmbed = {
          character: pendingTag[0],
          body: pendingTag.substr(1),
        };
        editorRef.current.getEditor().insertEmbed(range.index, 'tag', tag, 'user');
        editorRef.current.getEditor().insertText(range.index + 1, ' ', 'user');
        editorRef.current.getEditor().setSelection(range.index + 2, 0, 'user');
        // editorRef.current.getEditor().update('user');
        // setEditorValue(editorRef.current.getEditor().root.innerHTML + ' ');
      }
    }

    dispatch(setPendingTag(''));
  }, [dispatch, pendingTag, selection]);

  const throttledSave = useCallback(
    _.throttle((value: string) => dispatch(setDraftNoteAsync(value)), 1000, {
      trailing: true,
    }),
    [],
  );
  const { placeholder } = props;

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      // ['link', 'image', 'video'],
      // [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      // [{size: []}],
      // ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      // [{'list': 'ordered'}, {'list': 'bullet'},
      //  {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image', 'video'],
      // ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'tag',
    //'link',
    //'image',
    //'video',
  ];
  const [initialized, setInitialized] = useState(false);
  const appContext = useContext(AppContext);
  const onEditorChange = (content: any) => {
    setEditorValue(content);
    if (initialized) {
      throttledSave(content);
    }
  };

  const submitNote = () => {
    // TODO: parse this note before creating Note which will extract
    // TODO: all the datetimes, create an ID, get all tags, etc.
    console.log(editorValue);
    // TODO: get note body as non ascii
    // TODO: compile regexes? (here and in prefix matcher)

    // TODO: get tags out of contents (find all deltas that are inserts of data)
    //var blots = editorRef.current.getEditor().getContents();
    const editorHtml = editorRef!
      .current!.getEditor()
      .root.innerHTML.replace(/^(<div><br><\/div>)*/, '')
      .replace(/(<div><br><\/div>)*$/, '');
    const editorData = getDataFromEditor();
    let editorText = editorData?.text;
    let tags = editorData?.tagList ?? [];
    editorText = editorText?.replace(/^\n*/, '')?.replace(/\n*$/, '') ?? '';
    const editorContents = JSON.stringify(editorRef?.current?.getEditor().getContents());
    if (activeContext === undefined) {
      alert('Unable to add note - fatal error. Please restart.');
      return;
    }
    const note: PouchNote = PouchNoteCtor(
      activeContext,
      NoteType.Generic,
      tags,
      [],
      editorText,
      editorHtml,
      editorContents,
      {},
    );
    services.persistence.upsertNote(note);
    onEditorChange('');
    dispatch(setDraftNoteAsync(''));
    // Scroll to the bottom.
    appContext.scrollToBottom && appContext.scrollToBottom();
  };

  useEffect(() => {
    (async () => {
      // Rehydrate current note from state once on first mount
      // TODO: accept draft value as prop and save it under its own prop.

      // TODO: This is a hacky workaround because tags don't render correctly in the body of other notes unless one has been rendered before.
      // Can probably fix this.
      editorRef!.current!.getEditor().insertEmbed(0, 'tag', { character: '@', description: ' ' }, 'user');
      setEditorValue('');
      setEditorValue((await services.persistence.getSettings()).draftNote);
      setInitialized(true);
    })();
  }, []);

  const editorRef: MutableRefObject<ReactQuill | null> = useRef(null);

  const checkNoSelection = (): boolean => {
    const data = getDataFromEditor();
    return data?.selection?.length ? data?.selection?.length === 0 : true;
  };
  const checkPrefix = (regex: RegExp) => {
    const data = getDataFromEditor();
    if (data === null) return false;
    return regex.test(data.prefixText);
  };
  const checkSuffix = (regex: RegExp) => {
    const data = getDataFromEditor();
    if (data === null) return false;
    return regex.test(data.suffixText);
  };

  const getDataFromEditor = (): IEditorData | null => {
    if (editorRef.current === null) return null;
    const tagList: string[] = [];
    const editor = editorRef.current.getEditor();
    const unprivilegedEditor = editorRef.current.makeUnprivilegedEditor(editor);
    const text = unprivilegedEditor.getText();
    const selection = unprivilegedEditor.getSelection();
    let selectionStart = selection?.index ?? 0;
    let selectionEnd = (selection?.length ?? 0) + selectionStart;
    // Offset selection by the # of embeds that are found.
    let editorDeltas = unprivilegedEditor.getContents();
    let selectionStartOffset = 0;
    let selectionEndOffset = 0;
    let cursorPos = 0;
    editorDeltas.map((delta) => {
      if (typeof delta.insert === typeof '') {
        cursorPos += delta.insert.length;
      } else {
        cursorPos += 1;
        if (delta.insert['tag']?.body !== undefined) {
          // we've got a tag most likely
          tagList.push(delta.insert['tag'].character + delta.insert['tag'].body);
        }
        if (cursorPos < selectionStart) {
          selectionStartOffset += 1;
        }
        if (cursorPos < selectionEnd) selectionEndOffset += 1;
      }
    });
    selectionStart -= selectionStartOffset;
    selectionEnd -= selectionEndOffset;
    const prefixText = text.substring(0, selectionStart);
    const selectedText = text.substring(selectionStart, selectionEnd);
    const suffixText = text.substring(selectionEnd, text.length);
    return {
      text: text,
      selection: selection,
      selectedText: selectedText,
      tagList: tagList,
      prefixText: prefixText,
      suffixText: suffixText,
    };
  };

  const keyDown = (e: any) => {
    // TODO: save and restore selection in command pallette
    // if (e.key === '/' && checkNoSelection() && checkPrefix(/([\s.?!)]+$)|(^$)/)) {
    //   dispatch(showCommandPallette(CommandEmptyCtor()));
    //   e.preventDefault();
    // } else
    if (e.key === '@' && checkNoSelection() && checkPrefix(/([\s.?!)]+$)|(^$)/)) {
      dispatch(showCommandPallette(CommandTypedCtor(CommandType1.TagPeopleSearch)));
      e.preventDefault();
    } else if (e.key === '#' && checkNoSelection() && checkPrefix(/([\s.?!)]+$)|(^$)/)) {
      dispatch(showCommandPallette(CommandTypedCtor(CommandType1.TagTopicSearch)));
      e.preventDefault();
      // } else if (e.key === '!' && checkNoSelection() && checkPrefix(/([\s)]+$)|(^$)/)) {
      //   dispatch(showCommandPallette(CommandTypedCtor(CommandType1.TagPrioritySearch)));
      //   e.preventDefault();
      // } else if (e.key === '%' && checkNoSelection() && checkPrefix(/([\s.?!)]+$)|(^$)/)) {
      //   dispatch(showCommandPallette(CommandTypedCtor(CommandType1.TagToDoSearch)));
      //   e.preventDefault();
    } else if (e.key === 'Enter' && checkNoSelection() && checkPrefix(/\n\n\n$/) && checkSuffix(/^\n$/)) {
      submitNote();
      e.preventDefault();
    } else if (e.key === 'Enter' && e.ctrlKey) {
      submitNote();
      e.preventDefault();
    }
  };
  return (
    <div className={classes.cardWrapper}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <ReactQuill
            ref={editorRef}
            theme={'snow'}
            onChange={onEditorChange}
            onBlur={(previousSelection) => {
              // console.log('blur');
              // console.log(previousSelection);
              if (previousSelection !== null) {
                setEditorSelection(previousSelection);
              }
            }}
            onChangeSelection={(range) => {
              // console.log(range);
              if (range !== null) {
                setEditorSelection(range);
              }
            }}
            onKeyDown={keyDown}
            value={editorValue}
            modules={modules}
            formats={formats}
            bounds=".app"
            placeholder={placeholder}
          />
        </CardContent>
      </Card>
    </div>
  );
}
