import { getNewShorterShortId } from 'models/ShortId';

export interface PouchContext {
  _id: string;
  index: number;
  deleted: boolean;
  value: string;
}
export const PouchContextCtor = (index: number, value: string, deleted: boolean): PouchContext => {
  return {
    _id: 'c!' + getNewShorterShortId(),
    index: index,
    deleted: deleted,
    value: value,
  };
};

export const ContextIdFromDBKey = (id?: string) => {
  if (!id || id.length < 2) {
    return id;
  }
  return id.substring(2);
};

export const initialContexts: PouchContext[] = [
  {
    _id: 'c!Personal',
    index: 0,
    deleted: false,
    value: 'Personal',
  },
  {
    _id: 'c!Work----',
    index: 1,
    deleted: false,
    value: 'Work',
  },
  {
    _id: 'c!School--',
    index: 2,
    deleted: false,
    value: 'School',
  },
];
