import { PouchContext, initialContexts, ContextIdFromDBKey } from './models/PouchContext';
import { PouchNote, OnboardingNote } from './models/PouchNote';
import SearchService from 'services/SearchService';
import { PouchSettings, initialSettings } from './models/PouchSettings';
import PouchDB from 'pouchdb';
import upsert from 'pouchdb-upsert';
import { notesChangedAsync, setActiveContextAsync } from 'features/App/appSlice.thunks';
import { globalDispatch } from 'index';
import _ from 'lodash';
PouchDB.plugin(upsert);

const baseDBName = 'notebrook-default-signedout';
const settingsDBName = 'notebrook-local-settings';

export class MigrationException {
  public message: string;
  public name: string;
  constructor(message: string) {
    this.message = message;
    this.name = 'MigrationException';
  }
}

export interface IPersistenceService {
  migrate: (endpoint: string, databaseName: string, username: string, password: string) => Promise<void>;
  purge: (databaseName: string) => Promise<void>;

  initializeSettings: () => void;
  /* Callback registration */
  watchContextChanges: (action: (contexts: PouchContext[]) => void) => void;
  watchNoteChanges: (action: (notes: PouchNote[]) => void) => void;
  watchSettingsChanges: (action: (settings: PouchSettings) => void) => void;

  /* Settings management */
  getSettings: () => Promise<PouchSettings>;
  upsertSettings: (action: (settings: PouchSettings) => PouchSettings) => Promise<void>;

  /* Notes management */
  upsertNote: (note: PouchNote) => Promise<void>;
}

export type PersistenceServiceState = {
  baseDBName: string;
  activeDBName: string;
  isPurging: boolean;
  replicationHandler: any;
  activeContext?: PouchContext;
  activeDB: PouchDB.Database<{}>;
  baseDB: PouchDB.Database<{}>;
  settingsDB: PouchDB.Database<{}>;

  /* Callbacks when data changes */
  contextWatchAction?: (contexts: PouchContext[]) => void;
  noteWatchAction?: (notes: PouchNote[]) => void;
  settingsWatchAction?: (settings: PouchSettings) => void;
};

/* Automatically set compaction to true */
export default class PersistenceService implements IPersistenceService {
  private state: PersistenceServiceState;
  private searchService: SearchService;
  constructor(searchService: SearchService) {
    const baseName = baseDBName;
    const baseDB = new PouchDB(baseName, { auto_compaction: true });
    const settingsDB = new PouchDB(settingsDBName, { auto_compaction: true });
    this.state = {
      isPurging: false,
      baseDBName: baseName,
      replicationHandler: null,
      activeDBName: baseName,
      baseDB: baseDB,
      activeDB: baseDB,
      settingsDB: settingsDB,
    };
    this.searchService = searchService;
  }

  /*====================================
               DATABASES 
  ======================================*/
  public async initializeDatabases() {
    // If our base DB's settings already has a logged in user, migrate to that DB immediately.
    let dbTarget = (await this.getSettings()).remote;
    let isResuming = false;
    if (dbTarget.username && dbTarget.password && dbTarget.endpoint && dbTarget.dbName) {
      isResuming = true;
      await this.migrate(dbTarget.endpoint, dbTarget.dbName, dbTarget.username, dbTarget.password);
    }
    return isResuming;
  }

  private throttledNotification = _.throttle(() => globalDispatch(notesChangedAsync()), 500, {
    leading: true,
    trailing: true,
  });

  public async migrate(endpoint: string, databaseName: string, username: string, password: string) {
    if (this.state.isPurging) return;

    if (this.state.activeDBName !== baseDBName) {
      throw new MigrationException('Attempted to migrate when local DB was not active DB!');
    }

    const remoteDB = new PouchDB(endpoint + '/' + databaseName, {
      auth: {
        username: username,
        password: password,
      },
    });

    // Sync once to make sure we're in sync.
    this.state.baseDB.replicate.to(remoteDB).on('complete', async () => {
      // Once local documents have been added to the remote, set active DB to
      // the new remote,
      // purge all local changes,
      // and then sync remote.
      try {
        this.state.activeDBName = databaseName;
        this.state.activeDB = new PouchDB(databaseName);

        await this.state.baseDB.destroy();
        this.state.baseDB = new PouchDB(baseDBName, { auto_compaction: true });

        // Replication won't fire a complete event if there have been no changes, so we need to notify right away that there are changes.
        // Since this is throttled if a quick replication happens it won't fire again unless it's trailing so NBD.
        this.throttledNotification();

        this.state.replicationHandler = this.state.activeDB
          .sync(remoteDB, {
            live: true,
            retry: true,
          })
          .on('complete', async () => {
            console.log('Replication: Complete');
            this.throttledNotification();

            // set the context so the index will be rebuilt. only if it's not undefined (complete fires when replication is canceled too.)
            let contexts = await this.getContexts();
            if (contexts && contexts[0]) await this.setActiveContext(contexts[0]);
            this.throttledNotification();
            // dispatch(getRecentNotesThunk(50));
          })
          .on('change', (change: any) => {
            console.log('Replication: Change');
            // If there's an active context and there's docs in the change feed,
            // update them in the search index.
            if (this.state.activeContext && change && change.change && change.change.docs) {
              let activeContextNotePrefix = 'n!' + this.state.activeContext.value + '!';
              for (let c of change.change.docs) {
                if (c._id.substr(0, 11) === activeContextNotePrefix) {
                  this.searchService.updateNote(this.state.activeContext, c);
                }
              }
            }

            // Throttle change dispatches so a lot of notes syncing won't trigger a rerender for each note.
            this.throttledNotification();
            // dispatch(getRecentNotesThunk(50));
          })
          .on('error', () => {});
      } catch {}
      // .on('paused', function (info) {
      //   // replication was paused, usually because of a lost connection
      // })
      // .on('active', function (info) {

      //   dispatch(notesChangedAsync());
      // })
      // .on('error', function (err) {
      //   // totally unhandled error (shouldn't happen)
      // });
    });
  }

  public async purge(databaseName: string) {
    this.state.isPurging = true;
    const baseDB = new PouchDB(baseDBName, { auto_compaction: true });
    await this.searchService.resetIndexes();
    try {
      if (databaseName !== this.state.activeDBName) {
        //const dbToPurge = new PouchDB(databaseName);
        //await dbToPurge.destroy();

        this.state = {
          ...this.state,
          baseDBName: baseDBName,
          activeDBName: baseDBName,
          baseDB: baseDB,
          activeDB: baseDB,
        };
        this.state.isPurging = false;
        //globalDispatch(notesChangedAsync());
        let contexts = await this.getContexts();
        globalDispatch(setActiveContextAsync(contexts[0]._id));
      } else {
        this.state.replicationHandler?.cancel();
        this.state.replicationHandler?.on('complete', async () => {
          try {
            //const dbToPurge = new PouchDB(databaseName);
            //await dbToPurge.destroy();
            console.log('purge complete');
            this.state = {
              ...this.state,
              baseDBName: baseDBName,
              activeDBName: baseDBName,
              baseDB: baseDB,
              activeDB: baseDB,
            };
            this.state.isPurging = false;
            //globalDispatch(notesChangedAsync());
            let contexts = await this.getContexts();
            globalDispatch(setActiveContextAsync(contexts[0]._id));
          } catch {}
        });
      }
    } catch {}
    this.state.noteWatchAction && this.state.noteWatchAction(await this.getRecentNotes());
  }

  /*====================================
               SETTINGS 
  ======================================*/

  public async upsertSettings(action: (settings: PouchSettings) => PouchSettings) {
    let settings = await this.getSettings();
    let updatedDoc = action(settings);
    await this.state.settingsDB.upsert(updatedDoc._id, (old) => {
      return updatedDoc;
    });
    this.state.settingsWatchAction && this.state.settingsWatchAction(updatedDoc);
  }

  public async initializeSettings() {
    let settings = await this.getSettings();
    this.state.settingsWatchAction && this.state.settingsWatchAction(settings);
    return settings;
  }

  public async getSettings() {
    await this.state.settingsDB.putIfNotExists(initialSettings);
    // Store login in settings
    let settings: PouchSettings = await this.state.settingsDB.get<PouchSettings>('_local/settings');
    return settings;
  }

  public watchSettingsChanges = (action: (settings: PouchSettings) => void) => {
    this.state.settingsWatchAction = action;
  };

  /*====================================
                  NOTES
  ======================================*/

  public watchNoteChanges = (action: (notes: PouchNote[]) => void) => {
    this.state.noteWatchAction = action;
  };

  public getNotesByIds = async (ids: string[]): Promise<PouchNote[]> => {
    if (this.state.isPurging) return [];
    let pouchNotes: PouchNote[] = [];
    for (let id of ids) {
      pouchNotes.push(await this.state.activeDB.get<PouchNote>(id));
    }
    return pouchNotes;
  };

  public async getAllNotesForCurrentContext() {
    if (this.state.isPurging) return [];

    const contextId = ContextIdFromDBKey(this.state.activeContext?._id);

    let results = await this.state.activeDB.allDocs<PouchNote>({
      include_docs: true,
      startkey: 'n!' + contextId + '!',
      endkey: 'n!' + contextId + '!\ufff0',
    });
    return results.rows.map((item) => item.doc as PouchNote);
    // dispatch(setNotes(results.rows.map(item => PouchNoteFromDoc(item.doc)));
  }

  public async getRecentNotes() {
    if (this.state.isPurging) return [];

    let count: number = 25;
    // let currentContext = "someContext";
    const contextId = ContextIdFromDBKey(this.state.activeContext?._id);

    let results = await this.state.activeDB.allDocs<PouchNote>({
      include_docs: true,
      descending: true,
      limit: count,
      endkey: 'n!' + contextId + '!',
      startkey: 'n!' + contextId + '!\ufff0',
    });
    // The reason we're reversing is because we are limiting the # so we have to query in
    // sorted order then reverse it.

    return results.rows.map((item) => item.doc as PouchNote).reverse();
    // dispatch(setNotes(results.rows.map(item => PouchNoteFromDoc(item.doc)));
  }

  upsertNote = async (note: PouchNote) => {
    if (this.state.isPurging) return;

    await this.state.activeDB.upsert(note._id, (old) => {
      return note;
    });
    if (this.state.activeContext) {
      this.searchService.updateNote(this.state.activeContext, note);
    }
    this.state.noteWatchAction && this.state.noteWatchAction(await this.getRecentNotes());
  };

  /*====================================
                CONTEXTS
  ======================================*/

  public async upsertContext(action: (contexts: PouchContext[]) => void) {
    if (this.state.isPurging) return;

    alert('NOT IMPLEMENTED');
    // let settings = await this.getSettings();
    // let updatedDoc = action(settings);
    // await this.state.baseDB.upsert(updatedDoc._id, (old) => {
    //   return updatedDoc;
    // });
    // this.state.settingsWatchAction && this.state.settingsWatchAction(settings);
  }

  public async getContexts() {
    if (this.state.isPurging) return [];

    for (let ctx of initialContexts) {
      await this.state.activeDB.putIfNotExists(ctx);
    }

    await this.state.activeDB.putIfNotExists(OnboardingNote);

    let results = await this.state.activeDB.allDocs<PouchContext>({
      include_docs: true,
      startkey: 'c!',
      endkey: 'c!\ufff0',
    });
    const contexts: PouchContext[] = results.rows
      .map((item) => item.doc as PouchContext)
      .sort((a, b) => a.index - b.index);
    return contexts;
  }

  public watchContextChanges = (action: (contexts: PouchContext[]) => void) => {
    this.state.contextWatchAction = action;
  };

  // mergeSourceToDestThenPurgeSource(sourceName: string, destName: string) {}

  public async setActiveContext(context: PouchContext) {
    if (this.state.isPurging) return;

    this.state.activeContext = context;
    this.state.contextWatchAction && this.state.contextWatchAction(await this.getContexts());
    // Get ALL notes for context and pass to the search service to rebuild the context.
    const contextId = ContextIdFromDBKey(context._id);
    let results = await this.state.activeDB.allDocs<PouchNote>({
      include_docs: true,
      startkey: 'n!' + contextId + '!',
      endkey: 'n!' + contextId + '!\ufff0',
    });
    const notes: PouchNote[] = results.rows.map((item) => item.doc as PouchNote);
    // .sort((a, b) => a.index - b.index);

    this.searchService.rebuildIndexForContext(context, notes);
    // Reload notes when context changes.
    this.state.noteWatchAction && this.state.noteWatchAction(await this.getRecentNotes());
  }

  public async initializeContexts() {
    if (this.state.isPurging) return [];

    let contexts = await this.getContexts();
    this.state.contextWatchAction && this.state.contextWatchAction(contexts);
    return contexts;
  }

  // if (localDB === undefined) return;
  // let results = await localDB.allDocs({
  //   include_docs: true,
  //   // descending: true,
  //   endkey: 'c|\ufff0',
  //   startkey: 'c|',
  // });
  // let contexts: PouchContext[] | null = null;
  // if (results.total_rows > 0) {
  //   contexts = results.rows.map((item) => (item.doc as unknown) as PouchContext);
  // } else {
  //   // Create default contexts

  // }
  // dispatch(setContexts(contexts.sort((a, b) => a.index - b.index)));

  // // If there's an active context, get it and use it.
  // let activeContextRequested = getActiveContextId();
  // let pouchContext = _.find(contexts, (x) => x._id === activeContextRequested);

  // if (pouchContext !== undefined) {
  //   // Try and find context.
  //   dispatch(setActiveContext(pouchContext));
  //   dispatch(getRecentNotesThunk(50));
  // } else {
  //   // Didn't find context, use first one as default.
  //   dispatch(setActiveContext(contexts[0]));
  //   dispatch(getRecentNotesThunk(50));
  // }
  // // dispatch(setNotes(results.rows.map(item => PouchNoteFromDoc(item.doc)));
}
/*
export const resetDBThunk = (name: string): AppThunk => async (dispatch) => {
  localDB = new PouchDB(name);
  await localDB.destroy();
};

export const createDBThunk = (name: string): AppThunk => async (dispatch) => {
  localDB = new PouchDB(name, { auto_compaction: true });
};

export const addContextToDBThunk = (name: string): AppThunk => async (dispatch) => {
  if (localDB === undefined) return;
  const index =
    (
      await localDB.allDocs({
        endkey: 'c|\ufff0',
        startkey: 'c|',
      })
    ).total_rows + 1;
  const newContext = PouchContextCtor(index, name, false);
  await localDB.put(newContext);
  dispatch(getContextsWithInitializationThunk());
  dispatch(setActiveContext(newContext));
};

// If not
export const getContextsWithInitializationThunk = (): AppThunk => async (dispatch) => {
  if (localDB === undefined) return;
  let results = await localDB.allDocs({
    include_docs: true,
    // descending: true,
    endkey: 'c|\ufff0',
    startkey: 'c|',
  });
  let contexts: PouchContext[] | null = null;
  if (results.total_rows > 0) {
    contexts = results.rows.map((item) => (item.doc as unknown) as PouchContext);
  } else {
    // Create default contexts
    contexts = [
      {
        _id: 'c|CyJd2V',
        index: 0,
        disabled: false,
        value: 'Personal',
      },
      {
        _id: 'c|BNqE4A',
        index: 1,
        disabled: false,
        value: 'Work',
      },
      {
        _id: 'c|bq6Dtj',
        index: 2,
        disabled: false,
        value: 'School',
      },
    ];

    await localDB.put(contexts[0]);
    await localDB.put(contexts[1]);
    await localDB.put(contexts[2]);
  }
  dispatch(setContexts(contexts.sort((a, b) => a.index - b.index)));

  // If there's an active context, get it and use it.
  let activeContextRequested = getActiveContextId();
  let pouchContext = _.find(contexts, (x) => x._id === activeContextRequested);

  if (pouchContext !== undefined) {
    // Try and find context.
    dispatch(setActiveContext(pouchContext));
    dispatch(getRecentNotesThunk(50));
  } else {
    // Didn't find context, use first one as default.
    dispatch(setActiveContext(contexts[0]));
    dispatch(getRecentNotesThunk(50));
  }
  // dispatch(setNotes(results.rows.map(item => PouchNoteFromDoc(item.doc)));
};

export const getRecentNotesThunk = (count: number): AppThunk => async (dispatch, getState) => {
  if (localDB === undefined) return;
  let currentContext = getActiveContext(getState());
  let contextId = ContextIdFromDBKey(currentContext?._id);
  let results = await localDB.allDocs({
    include_docs: true,
    // descending: true,
    limit: count,
    endkey: 'n|' + contextId + '|\ufff0',
    startkey: 'n|' + contextId + '|',
  });
  console.log(results);
  dispatch(setNotes(results.rows.map((item) => (item.doc as unknown) as PouchNote)));
  // dispatch(setNotes(results.rows.map(item => PouchNoteFromDoc(item.doc)));
};

export const addNoteToDBThunk = (note: PouchNote): AppThunk => async (dispatch) => {
  if (localDB === undefined) return;
  await localDB.put(note);
  dispatch(getRecentNotesThunk(50));
};

export const stopRemoteSyncThunk = (): AppThunk => async (dispatch) => {
  await syncHandler?.cancel();
  //dispatch(syncCanceled)
};

// TODO: use couchDBEndpoint, couchDBUsername, and couchDBPassword from local
export const startRemoteSyncThunk = (): AppThunk => async (dispatch) => {
  if (localDB === undefined) return;
  // const pouchDB = new PouchDB('http://192.168.1.100:5984/notebrook-local', {
  // auth:
  //     {
  //         username: "test",
  //         password: "test"
  //     }
  // });
  remoteDB = new PouchDB('http://finn:5984/notebrook-local');
  // Sync once to make sure we're in sync.
  localDB
    .sync(remoteDB)
    .on('complete', function () {
      // yay, we're in sync!
      getContextsWithInitializationThunk();
      // dispatch(getRecentNotesThunk(50));
    })
    .on('error', function (err) {
      // boo, we hit an error!
    });
  syncHandler = localDB
    .sync(remoteDB, {
      live: true,
      retry: true,
    })
    .on('complete', () => {
      getContextsWithInitializationThunk();
      // dispatch(getRecentNotesThunk(50));
    })
    .on('change', () => {
      getContextsWithInitializationThunk();
      // dispatch(getRecentNotesThunk(50));
    })
    .on('paused', function (info) {
      // replication was paused, usually because of a lost connection
    })
    // .on('active', function (info) {
    //   // replication was resumed
    // })
    .on('error', function (err) {
      // totally unhandled error (shouldn't happen)
    });
};
*/
