import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import { useTheme } from '@material-ui/core/styles';

import { useStyles } from './SideMenuStyles';
import { SideMenuList } from './SideMenuList';

interface ISideMenuProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export function SideMenu(props: ISideMenuProps) {
  const { mobileOpen, handleDrawerToggle } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <nav className={classes.drawer} aria-label="main menu">
      {/* <Hidden mdUp implementation="css"> */}
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SideMenuList visible={mobileOpen} handleDrawerToggle={handleDrawerToggle}></SideMenuList>
      </Drawer>
      {/* </Hidden> */}
      {/* <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <SideMenuList></SideMenuList>
        </Drawer>
      </Hidden> */}
    </nav>
  );
}
