// export type NoteCollection = Note[];

export enum NoteType {
  Generic = 1,
  Meeting = 101,
  Task = 201,
}

export const NoteTypeShortDescriptor: Record<NoteType, string> = {
  [NoteType.Generic]: 'g',
  [NoteType.Meeting]: 'm',
  [NoteType.Task]: 't',
};
// export class Note {
//   public Id: Guid;
//   public PouchId: string;
//   public Context: Guid;
//   public ContextName: string;
//   public Tags: Tag[];

//   constructor(note: PouchNote) {
//     this.PouchId = note._id;
//     // Parse Pouch ID into derived properties
//     this.Id = ''; // parse pouch note id
//     // for each one, go through and map the tags.
//     this.Tags = note.tags.map((pouchTag) => new Tag(pouchTag));
//   }
//   Id: Guid;
//   _id: string;
//   Tags: TagCollection;
//   Type: NoteType;
//   Title?: string;
//   Created: Date;
//   Updated: Date;
//   Body: string;
//   Metadata?: object;
// }

// export type MeetingNote = Note & {
//   Type: NoteType.Meeting;
//   Metadata: {
//     MeetingName: string;
//   };
// };
