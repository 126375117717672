export enum CommandType1 {
  Generic = 1,
  TagTopicSearch = 101,
  TagPeopleSearch = 201,
  TagToDoSearch = 301,
  TagPrioritySearch = 401,
}

export const CommandType1Descriptor: Record<CommandType1, string> = {
  [CommandType1.Generic]: '',
  [CommandType1.TagTopicSearch]: 'Topic',
  [CommandType1.TagPeopleSearch]: 'People',
  [CommandType1.TagToDoSearch]: 'ToDo',
  [CommandType1.TagPrioritySearch]: 'Priority',
};
export type Command = {
  commandType1: CommandType1;
  value: string;
};
export const CommandEmptyCtor = (): Command => {
  return {
    commandType1: CommandType1.Generic,
    value: '',
  };
};
export const CommandTypedCtor = (commandType1: CommandType1) => {
  return {
    commandType1: commandType1,
    value: '',
  };
};
