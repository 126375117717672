import { AppThunk } from 'features/rootReducer';
import { loginAsync as loginApiAsync, signupAsync as signupApiAsync } from 'api';
import {
  loginStart,
  loginFailed,
  loginSuccess,
  signupFailed,
  signupSuccess,
  signupStart,
  setActiveContext,
  searchStart,
  searchComplete,
  logoutStart,
  resumeSession,
} from './appSlice';
import { services } from './App';
import { SearchParameters } from './appSlice.state';
import { CheckFilters } from 'services/SearchService';

export const loginAsync = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch(loginStart());
  const response = await loginApiAsync(email, password);
  if (response.type !== 'success') {
    dispatch(loginFailed(response.errorMessage ? response.errorMessage : 'Unknown Error Occurred.'));
  } else {
    await services.persistence.migrate(
      response.couchDBEndpoint,
      response.couchDBDatabaseName,
      response.couchDBUserName,
      response.couchDBPassword,
    );

    await services.persistence.upsertSettings((settings) => {
      settings.remote = {
        endpoint: response.couchDBEndpoint,
        username: response.couchDBUserName,
        password: response.couchDBPassword,
        dbName: response.couchDBDatabaseName,
      };
      return settings;
    });
    dispatch(loginSuccess(response));
  }
};
export const logoutAsync = (): AppThunk => async (dispatch, getState) => {
  dispatch(logoutStart());
  let settings = await services.persistence.getSettings();
  if (settings.remote?.dbName) {
    await services.persistence.purge(settings.remote.dbName);
  }
  await services.persistence.upsertSettings((settings) => {
    settings.remote = {};
    return settings;
  });
};
export const notesChangedAsync = (): AppThunk => async (dispatch, getState) => {
  console.log('notes changed');
  dispatch(startSearchAsync(getState().app.search));
};

export const removeSearchTagAsync = (tag: string): AppThunk => async (dispatch, getState) => {
  let newIds = getState().app.search.tag_ids.filter((x) => x !== tag);

  dispatch(startSearchAsync({ ...getState().app.search, tag_ids: newIds }));
};

export const signupAsync = (email: string, password: string, fullName: string, promoCode: string): AppThunk => async (
  dispatch,
) => {
  dispatch(signupStart());
  const response = await signupApiAsync(email, password, fullName, promoCode);
  if (response.type !== 'success') {
    dispatch(signupFailed(response.errorMessage ? response.errorMessage : 'Unknown Error Occurred.'));
  } else {
    await services.persistence.migrate(
      response.couchDBEndpoint,
      response.couchDBDatabaseName,
      response.couchDBUserName,
      response.couchDBPassword,
    );

    await services.persistence.upsertSettings((settings) => {
      settings.remote = {
        endpoint: response.couchDBEndpoint,
        username: response.couchDBUserName,
        password: response.couchDBPassword,
        dbName: response.couchDBDatabaseName,
      };
      return settings;
    });

    dispatch(signupSuccess(response));
  }
};

export const initializeAsync = (prefersDarkMode: boolean): AppThunk => async (dispatch) => {
  let settings = await services.persistence.initializeSettings();
  // This will set state context through the watchers.
  let contexts = await services.persistence.initializeContexts();

  // Set up dark mode if it hasn't been set up already.
  if (settings && !settings.hasDarkModeBeenSetBefore) {
    await services.persistence.upsertSettings((settings) => {
      settings.darkMode = prefersDarkMode;
      settings.hasDarkModeBeenSetBefore = true;
      return settings;
    });
  }
  let activeContextId = '';
  if (settings && settings.activeContextId !== '') {
    let target = contexts.find((x) => !x.deleted && x._id === settings.activeContextId);
    if (target) activeContextId = settings.activeContextId;
  }
  // Either the active context was not set in settings, or it was not found.
  if (activeContextId === '') {
    activeContextId = contexts.find((x) => !x.deleted)?._id ?? '';
  }

  let isResuming = await services.persistence.initializeDatabases();
  if (isResuming) {
    dispatch(resumeSession());
  }
  setTimeout(() => dispatch(setActiveContextAsync(activeContextId)), 250);
  // let settings = await services.persistence.getSettings();
  // dispatch(setSettings(settings));
};
export const toggleDarkModeAsync = (): AppThunk => async (dispatch, getState) => {
  await services.persistence.upsertSettings((settings) => {
    settings.darkMode = !settings.darkMode;
    return settings;
  });
};

export const setActiveContextAsync = (contextId: string): AppThunk => async (dispatch, getState) => {
  let state = getState().app;
  let settings = state.settings;
  const targetContext = state.contexts.find((x) => x._id === contextId);
  if (settings && targetContext !== undefined) {
    await services.persistence.upsertSettings((settings) => {
      settings.activeContextId = contextId;
      return settings;
    });
    await services.persistence.setActiveContext(targetContext);
    dispatch(setActiveContext(targetContext));
    dispatch(resetSearchAsync());
  }
};

export const resetSearchAsync = (): AppThunk => async (dispatch, getState) => {
  dispatch(
    searchStart({
      loading: false,
      active: false,
      term: '',
      tag_ids: [],
      favorites_only: false,
      include_deleted: false,
    }),
  );
  dispatch(searchComplete(await services.persistence.getRecentNotes()));
};

export const startSearchAsync = (params: SearchParameters): AppThunk => async (dispatch, getState) => {
  dispatch(searchStart(params));
  let appState = getState().app;
  let context = appState.activeContext;
  if (context === undefined) {
    return;
  }
  if (params.term === '' && params.tag_ids.length === 0) {
    dispatch(searchComplete(await services.persistence.getRecentNotes()));
    return;
  } else if (params.term === '') {
    // No term but filters are in place if we got here, so will have to filter all notes.
    let notes = await services.persistence.getAllNotesForCurrentContext();
    let results = notes.filter((note) => CheckFilters(params, note));
    dispatch(searchComplete(results));
    return;
  }
  let searchResults = await services.search.searchNotes(context, params);
  let pouchNotes = await services.persistence.getNotesByIds(searchResults);
  // sort results by creation date.
  let sortedNotes = pouchNotes.sort((a, b) => a.created - b.created);
  dispatch(searchComplete(sortedNotes));
};

export const setDraftNoteAsync = (draftNote: string): AppThunk => async (dispatch, getState) => {
  await services.persistence.upsertSettings((settings) => {
    settings.draftNote = draftNote;
    return settings;
  });
};
