export interface PouchSettings {
  _id: string;
  darkMode: boolean;
  hasDarkModeBeenSetBefore: boolean;
  activeContextId: string;
  remote: RemoteDBSettings;
  draftNote: string;
}

export interface RemoteDBSettings {
  username?: string;
  password?: string;
  endpoint?: string;
  dbName?: string;
}

export const initialSettings: PouchSettings = {
  _id: '_local/settings',
  darkMode: false,
  hasDarkModeBeenSetBefore: false,
  activeContextId: '',
  remote: {},
  draftNote: '',
};

// import { PouchNote } from 'services/PersistenceService/models/PouchNote';

// /* Use localstorage for basic settings on this device (like Dark Mode) */
// export type AppSettings = {
//   darkMode: boolean;
// };

// export const saveLocalStorageSettings = (settings: AppSettings) => {
//   localStorage.setItem('appsettings', JSON.stringify(settings));
// };
// export const getLocalStorageSettings = (): AppSettings | undefined => {
//   const settings = localStorage.getItem('appsettings');
//   if (settings == null) return;
//   return JSON.parse(settings) as AppSettings;
// };

// export const saveLocalStorageDraftNote = (html: string) => {
//   localStorage.setItem('draftnote', html);
// };

// export const getLocalStorageDraftNote = (): string | null => {
//   return localStorage.getItem('draftnote');
// };

// export const saveLocalStorageActiveContext = (contextId: string) => {
//   localStorage.setItem('activecontext', contextId);
// };

// export const getLocalStorageActiveContext = (): string | null => {
//   return localStorage.getItem('activecontext');
// };

// export const PouchSettingsCtor = (normalizedUsername: string): PouchSettings => {
//   return { _id: 'PouchDBConfig', normalizedUsername: normalizedUsername };
// };
