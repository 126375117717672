import React, { useEffect, useContext, useRef, useCallback } from 'react';
import './App.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Main from 'features/Main/Main';
import Login from 'features/Login/Login';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Signup from 'features/Signup/Signup';
import { setContexts, setNotes, setSettings, setSignupOpen } from './appSlice';
import { useDispatch } from 'react-redux';
import { getIsDarkMode, getIsSignupOpen } from './appSlice.selectors';
import { useTypedSelector } from 'features/rootReducer';
import ServiceCoordinator from 'services/ServiceCoordinator';
import { PouchNote } from 'services/PersistenceService/models/PouchNote';
import { PouchContext } from 'services/PersistenceService/models/PouchContext';
import { PouchSettings } from 'services/PersistenceService/models/PouchSettings';
import { initializeAsync } from './appSlice.thunks';
import { useHotkeys } from 'react-hotkeys-hook';

export const services = new ServiceCoordinator();

export interface IAppContext {
  scrollTopRef?: React.RefObject<HTMLDivElement>;
  scrollToTop?: () => void;
  scrollToBottom?: () => void;
  scrollBottomRef?: React.RefObject<HTMLDivElement>;
}

export const AppContext = React.createContext<IAppContext>({});

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const dispatch = useDispatch();
  const signupOpen = useTypedSelector(getIsSignupOpen);
  // const
  useEffect(() => {
    /* Perform initial setup of service coordinator. */
    services.persistence.watchContextChanges((contexts: PouchContext[]) => {
      dispatch(setContexts(contexts));
    });
    services.persistence.watchNoteChanges((notes: PouchNote[]) => {
      dispatch(setNotes(notes));
    });
    services.persistence.watchSettingsChanges((settings: PouchSettings) => {
      dispatch(setSettings(settings));
    });

    // dispatch(initializeAsync(prefersDarkMode));
    // Force no dark mode for alpha launch
    dispatch(initializeAsync(false));

    // // TODO: move this to login / logout to start and stop sync.
    // dispatch(startRemoteSyncThunk());
  }, [dispatch, prefersDarkMode]);

  const isDarkMode = useTypedSelector(getIsDarkMode);
  const theme = React.useMemo(
    () =>
      isDarkMode
        ? createMuiTheme({
            palette: {
              primary: {
                main: '#0d47a1',
              },
              secondary: {
                main: '#f50057',
              },
              type: 'dark',
            },
          })
        : createMuiTheme({
            palette: {
              primary: {
                main: '#2196f3',
              },
              secondary: {
                main: '#f50057',
              },
              type: 'light',
            },
          }),
    [isDarkMode],
  );
  const appContext = useContext(AppContext);

  appContext.scrollTopRef = useRef<HTMLDivElement>(null);
  appContext.scrollBottomRef = useRef<HTMLDivElement>(null);

  appContext.scrollToBottom = useCallback(() => {
    appContext.scrollBottomRef &&
      appContext.scrollBottomRef.current &&
      appContext.scrollBottomRef.current.scrollIntoView({ behavior: 'auto' });
  }, [appContext.scrollBottomRef]);
  appContext.scrollToTop = useCallback(() => {
    appContext.scrollTopRef &&
      appContext.scrollTopRef.current &&
      appContext.scrollTopRef.current.scrollIntoView({ behavior: 'auto' });
  }, [appContext.scrollTopRef]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AppContext.Provider value={appContext}>
        <div ref={appContext.scrollTopRef} id="scrollTargetTop" />
        <div className="App">
          <Router>
            <Main />
            <Signup open={signupOpen} />
          </Router>
        </div>

        <div ref={appContext.scrollBottomRef} id="scrollTargetBottom" />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
