import React from 'react';
import { Typography } from '@material-ui/core';

export function Search() {
  let savedNotes: string[] = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  ];
  return (
    <div>
      <p>Search!</p>
      {savedNotes.map((note) => (
        <Typography paragraph>{note}</Typography>
      ))}
    </div>
  );
}
