import ReactQuill from 'react-quill';
import { Chip, Avatar } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
let Embed = ReactQuill.Quill.import('blots/embed');

export type TagEmbed = {
  character: string;
  body: string;
};

interface ITagChipProps {
  embed: TagEmbed;
}

function TagChip(props: ITagChipProps) {
  const { embed } = props;
  // const dispatch =
  return (
    <Chip
      avatar={<Avatar>{embed.character}</Avatar>}
      label={embed.body}
      color={embed.character === '@' ? 'primary' : 'secondary'}
      variant="outlined"
    />
  );
}

class TagBlot extends Embed {
  static create(tag: TagEmbed) {
    let node: HTMLSpanElement = super.create();
    console.log('Creating embed');
    //onDelete={handleDelete}

    // node.innerHTML = '<span class="ql-notebrook-tag-char">' + tag.character + '</span>';
    let body = document.createElement('span');
    ReactDOM.render(<TagChip embed={tag} />, body);
    node.appendChild(body);
    // node.dataset['tag'] = JSON.stringify(tag);

    // node.setAttribute('src', data);
    // Set non-format related attributes with static values
    // node.setAttribute('frameborder', '0');
    // node.setAttribute('allowfullscreen', true);
    TagBlot.setDataValues(node, tag);
    return node;
  }

  static setDataValues(element: any, data: any) {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode: any) {
    return domNode.dataset;
  }
  format(name: any, value: any) {
    console.log('Format');
  }
  //   static formats(node) {
  //     // We still need to report unregistered embed formats
  //     let format = {};
  //     if (node.hasAttribute('height')) {
  //       format.height = node.getAttribute('height');
  //     }
  //     if (node.hasAttribute('width')) {
  //       format.width = node.getAttribute('width');
  //     }
  //     return format;
  //   }

  //   static value(node) {
  //     return node.getAttribute('src');
  //   }

  //   format(name, value) {
  //     // Handle unregistered embed formats
  //     if (name === 'height' || name === 'width') {
  //       if (value) {
  //         this.domNode.setAttribute(name, value);
  //       } else {
  //         this.domNode.removeAttribute(name, value);
  //       }
  //     } else {
  //       super.format(name, value);
  //     }
}

TagBlot.blotName = 'tag';
TagBlot.tagName = 'span';
TagBlot.className = 'ql-notebrook-tag';

ReactQuill.Quill.register('formats/tag', TagBlot, false);

export default TagBlot;
