// We're calling these short dates but they're really epoch timestamps.
export function getCurrentShortDate() {
  return new Date().getTime();
}

export function getDateFromShortDate(shortDate: number) {
  return new Date(shortDate);
}

export function getLocaleStringFromShortDate(shortDate: number) {
  let date = getDateFromShortDate(shortDate);
  let currentDate = new Date();
  if (
    currentDate.getDay() === date.getDay() &&
    currentDate.getMonth() === date.getMonth() &&
    currentDate.getFullYear() === date.getFullYear()
  ) {
    // Same exact local date, only return date string.
    return date.toLocaleTimeString();
  } else {
    return date.toLocaleString();
  }
}
