import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  getIsCommandPalletteActive,
  getCommandPalletteBaseCommand,
  getIsLoggingIn,
  getProcessingError,
  getIsAuthenticated,
  getIsSigningUpOrLoggingIn,
} from 'features/App/appSlice.selectors';
import {
  hideCommandPallette,
  setPendingTag,
  clearProcessingError,
  setSignupOpen,
  setIsSigningUpOrLoggingIn,
} from 'features/App/appSlice';
import { useTypedSelector } from 'features/rootReducer';
import { Typography, TextField, useMediaQuery, CircularProgress, Snackbar } from '@material-ui/core';
import { AutocompleteItem } from 'services/SearchService';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SignupForm } from './SignupForm';
import { LoginForm } from './LoginForm';
import { useStyles } from './SignupStyles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

export interface ISignupProps {
  open: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface ISignupFormProps {
  attemptedSubmit: boolean;
  resetAttemptedSubmit: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Signup(props: ISignupProps) {
  const { open } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  //const fullScreen = false;
  const theme = useTheme();
  const isSigningUp = useTypedSelector(getIsLoggingIn);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSignup = useTypedSelector(getIsSigningUpOrLoggingIn);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const processingError = useTypedSelector(getProcessingError);
  const isAuthenticated = useTypedSelector(getIsAuthenticated);
  const resetAttemptedSubmit = () => setAttemptedSubmit(false);

  const toggleForm = () => {
    dispatch(clearProcessingError());
    dispatch(setIsSigningUpOrLoggingIn(!isSignup));
  };
  const dialogClose = () => {
    if (isAuthenticated) {
      setIsSnackOpen(true);
    }
    dispatch(setSignupOpen(false));
  };

  return (
    <div>
      <Snackbar open={isSnackOpen} autoHideDuration={6000} onClose={() => setIsSnackOpen(false)}>
        <Alert onClose={() => setIsSnackOpen(false)} severity="success">
          {isSignup ? 'Successfully created account and logged in.' : 'You are now logged in.'}
        </Alert>
      </Snackbar>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={dialogClose}
        aria-labelledby="signup-dialog-title"
        transitionDuration={{ appear: 800, enter: 0, exit: 0 }}
      >
        <DialogTitle id="signup-dialog-title" onClose={() => dispatch(setSignupOpen(false))}>
          {isSignup ? 'Sign Up' : 'Sign In'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: theme.spacing(3) }}>
            {isSignup ? (
              <SignupForm attemptedSubmit={attemptedSubmit} resetAttemptedSubmit={resetAttemptedSubmit} />
            ) : (
              <LoginForm attemptedSubmit={attemptedSubmit} resetAttemptedSubmit={resetAttemptedSubmit} />
            )}
            {processingError && (
              <Typography color="error" variant="h6">
                {processingError}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        {isSignup ? (
          <DialogActions>
            <Button disabled={isSigningUp} onClick={toggleForm} color="primary">
              Sign In Instead
            </Button>
            <Button
              disabled={isSigningUp}
              onClick={() => {
                setAttemptedSubmit(true);
              }}
              color="primary"
            >
              Sign Up
              {isSigningUp && <CircularProgress className={classes.progressSpinner} />}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button disabled={isSigningUp} onClick={toggleForm} color="primary">
              Sign Up Instead
            </Button>
            <Button
              disabled={isSigningUp}
              onClick={() => {
                setAttemptedSubmit(true);
              }}
              color="primary"
            >
              Sign In
              {isSigningUp && <CircularProgress className={classes.progressSpinner} />}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
