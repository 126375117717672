import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Quill } from 'react-quill';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      margin: theme.spacing(1),
    },
    card: {
      maxWidth: 1800,
      margin: 'auto',
    },
    cardContent: {
      padding: theme.spacing(1),
      paddingBottom: '8px!important',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }),
);

/* Configure quill to use <div> instead of <p> because it puts them everywhere and they cause layout issues */
var Block = Quill.import('blots/block');

Block.tagName = 'DIV';
Quill.register(Block, true);
