import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';

import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import rootReducer from 'features/rootReducer';
import { AppConfig } from 'config/AppConfig';
import { devConfig } from 'config/config.development';
import { prodConfig } from 'config/config.production';

const preloadedState = {};

let localConfig: AppConfig = devConfig;

const store = configureStore({
  reducer: rootReducer,
  // middleware: [loggerMiddleware, ...getDefaultMiddleware<RootState>()]  as const,
  preloadedState,
  // enhancers: [monitorReducersEnhancer]
});

if (process.env.NODE_ENV !== 'production') {
  //@ts-ignore
  if (module.hot) {
    // console.log('Setting up hot reload for reducers.');
    //@ts-ignore
    module.hot.accept();
    // //@ts-ignore
    // module.hot.accept('./features/rootReducer', () => {
    //   store.replaceReducer(rootReducer);
    //   console.log('replaced root reducer.');
    // });
    // console.log('done.');
  }
  /* Declare variables */
} else {
  localConfig = prodConfig;
}

const render = () => {
  /* Use CommonJS require syntax to allow hot reloading of root component */
  const App = require('./features/App/App').default;
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export const config = localConfig;

render();

/* Export AppDispatch so that the dispatch is strongly typed */
export type AppDispatch = typeof store.dispatch;
export const globalDispatch = store.dispatch;
//@ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  //@ts-ignore
  module.hot.accept('./features/App/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
